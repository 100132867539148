import './EmptyState.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function EmptyState() {
  return (
    <main className="d-flex align-items-center">
      <Container>
        <Row className="justify-content-center text-center ">
          <Col xs={8} >
            <h1>404</h1>
            <p>We are sorry, but the page you requested was not found.</p>
          </Col>
          <Col xs={8}>
            <Button href="/" variant="primary">Go Home</Button>
          </Col>
        </Row>
      </Container>
    </main>
    )
}

export default EmptyState
