import { Form, FormControl, InputGroup, Button,FormLabel } from "react-bootstrap";
import { Search, ChevronRight  } from "react-iconly";

import "./SearchBar.scss";

const SearchBar = ({ onSearch }) => {
  const handleSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.elements.searchTerm.value;
    onSearch(searchTerm);
  };
  const handleKeyUp = (e) => {
    if(e.target.value && e.target.value.length >3){
      const searchTerm = e.target.value;
      onSearch(searchTerm);
    }
  };
  return (
    <Form onSubmit={handleSearch} className="searchbar-form">
      <InputGroup>
        <InputGroup.Text className="px-3">
          <Search width={16} height={16} />
        </InputGroup.Text>
        <FormLabel htmlFor="search" className="visually-hidden">Search</FormLabel>
        <FormControl type="text" placeholder="Search" name="searchTerm" className="px-0" id="search"  onKeyUp={handleKeyUp} autoComplete="off"/>
        <Button
          type="submit"
          variant="gray"
          className="d-inline border-0 px-2 submit-btn"
        >
           <ChevronRight width={16} height={16} primaryColor="#264687" />
           <span className="visually-hidden">
            Search Button
           </span>
        </Button>
      </InputGroup>
    </Form>
  );
};

export default SearchBar;
