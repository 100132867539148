import { useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubNavigation from "../../../components/SubNavigation/SubNavigation";
import CaseReference from "../../../components/Case/CaseReference";
import DetailsTab from "./DetailsTab/DetailsTab";
import DossierTab from "./DossierTab/DossierTab";
import FormEditorTab from "./FormEditorTab/FormEditorTab";
import InfoCard from "../../../components/InfoCard/InfoCard";
import { CovertDates } from "../../../utils/func";
import BtnSidebar from "../../../components/BtnSidebar/BtnSidebar";
import casedocs from "../../../../public/demo/casedocuments.json";
import partiesJson from "../../../../public/demo/case_parties.json";
import { fetchCaseById } from "../../../services/apiService";

import "./Case.scss";
const Case = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();
  const [sidebarPosition, setsidebarPosition] = useState(0);
  const divRef = useRef(null);
  const [dossier, setDossier] = useState(null);
  const [parties, setParties] = useState(null);
  const isCreateCasePage = location.pathname.startsWith("/cases/create");
  const [casedat, setCase] = useState(null);
  // const [caseDetails, setCaseDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const caseData = await fetchCaseById(id);
        setCase(caseData);
      } catch (err) {
        setError("Error fetching case data");
        console.error(err);
      }
    };
    if (isCreateCasePage) {
      setCase({
        taskId: id,
        claimant: [
          {
            partyId: {
              id: "e7c72b3e-77f0-4e67-bc9b-df8390a0fa21",
              version: 1,
            },
            name: "John Doe",
            roleInCaseCode: "CLAIMANT",
            roleInCaseShort: "Plaintiff",
          },
        ],
        defendant: [
          {
            partyId: {
              id: "ae8d11f1-3a95-4be8-a5c6-e7a01817c546",
              version: 1,
            },
            name: "Jane Smith",
            roleInCaseCode: "DEFENDANT",
            roleInCaseShort: "Defendant",
          },
        ],
        amount: "10,000.00 EUR",
        caseNumber: "",
        organizational_entity: "High Court",
        status: "OPEN",
        created_at: null,
        updated_at: null,
      });
    } else {
      fetchCaseData(); // Fetch case data if not creating a case
    }
    let response = casedocs;
    setDossier(response);
  }, [id, setCase]);

  useEffect(() => {
    if (isCreateCasePage) {
      setParties([]);
    } else {
      setParties(partiesJson);
    }
  }, [id]);

  useEffect(() => {
    const getBottomPosition = () => {
      if (divRef.current) {
        const { bottom } = divRef.current.getBoundingClientRect();
        setsidebarPosition(bottom);
      }
    };
    setTimeout(() => {
      getBottomPosition();
    }, "1000");
    return () => {};
  }, []);
  {
    error && <div>{error}</div>;
  }
  {
    /* Error state UI */
  }
  return (
    <>
      {casedat != null && (
        <>
          <Container
            ref={divRef}
            fluid
            className={`container-wrapper border-bottom single-case  ${
              isCreateCasePage ? "create-case" : ""
            }`}
          >
            <h1 className="visually-hidden">Single Case</h1>
            <Row>
              <Col xs={12}>
                <div className="d-flex flex-md-nowrap flex-wrap justify-content-md-between justify-content-center">
                  <div className="w-100">
                    <CaseReference id={casedat.caseNumber} />
                  </div>
                  <SubNavigation
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isdisabled={isCreateCasePage}
                  />
                  <div className="btn-actions w-100">
                    <BtnSidebar
                      state={id}
                      options={dossier}
                      caseData={casedat}
                      type="createnote"
                      divRef={divRef}
                      label="Create new note"
                      disabled={isCreateCasePage}
                    />
                    <BtnSidebar
                      state={id}
                      caseData={casedat}
                      options={dossier}
                      type="createtask"
                      divRef={divRef}
                      label="Create new task"
                      disabled={isCreateCasePage}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row className={`${isCreateCasePage ? "disabled" : ""}`}>
              {casedat.caseMetadata != null &&
                casedat.caseMetadata.authority_id && (
                  <Col xl={2} md={4}>
                    <InfoCard
                      label="Authority ID"
                      data={casedat.caseMetadata.authority_id}
                    />
                  </Col>
                )}
              {casedat.caseMetadata != null &&
                casedat.caseMetadata.assignedToDepartment && (
                  <Col xl={2} md={4}>
                    <InfoCard
                      label="Department ID"
                      data={casedat.caseMetadata.assignedToDepartment}
                    />
                  </Col>
                )}

              {casedat.claimant && (
                <Col xl={2} md={4}>
                  <InfoCard
                    label="Claimant"
                    data={casedat.claimant[0].name}
                    length={casedat.claimant.length}
                  />
                </Col>
              )}
              {casedat.defendant && (
                <Col xl={2} md={4}>
                  <InfoCard
                    label="Defendant"
                    data={casedat.defendant[0].name}
                    length={casedat.defendant.length}
                  />
                </Col>
              )}
              {casedat.amount && (
                <Col xl={2} md={4}>
                  <InfoCard label="Amount(€)" data={casedat.amount} />
                </Col>
              )}
              {casedat.created_at && (
                <Col xl={2} md={4}>
                  <InfoCard
                    label="Created on:"
                    data={<CovertDates date={casedat.created_at} />}
                  />
                </Col>
              )}
            </Row>
          </Container>
          {parties !== null && casedat && (
            <div className="tab-content add-scroll">
              {activeTab === 1 && (
                <>
                  <DetailsTab
                    isCreateCasePage={isCreateCasePage}
                    sidebarPosition={sidebarPosition}
                    id={id}
                    parties={parties}
                  />
                </>
              )}
              {activeTab === 2 && (
                <DossierTab
                  id={id}
                  divRef={divRef}
                  dossier={dossier}
                  setDossier={setDossier}
                  casedat={casedat}
                />
              )}
              {activeTab === 3 && (
                <FormEditorTab
                  id={id}
                  divRef={divRef}
                  casedat={casedat}
                  parties={parties}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Case;
