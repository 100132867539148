import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./DossierContent.scss";

const PreviewPDF = ({ link }) => {
  return (
    <>
      <div className="preview-border pdfPreview" tabIndex="0">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl={link} />
        </Worker>
      </div>
    </>
  );
};

export default PreviewPDF;
