interface ICaseSearchModel {
  case_id: string; // <uuid>
  caseNumber: string; // <string>
  organizational_entity: string; // <string>
  claimant: string; // <string>
  defendant: string; // <string>
  amount: number; // <integer>
  status: string; // <boolean>
  created_at: Date; // <dateTime>
  updated_at: Date; // <dateTime>
  user_id: string; // <uuid>
}

export class CaseSearchModel {
  case_id: string;
  caseNumber: string;
  organizational_entity: string;
  claimant: string;
  defendant: string;
  amount: number;
  status: string;
  created_at: Date;
  updated_at: Date;
  user_id: string;

  constructor(data: ICaseSearchModel) {
    this.case_id = data.case_id || "";
    this.caseNumber = data.case_number || "";
    this.organizational_entity = data.organizational_entity || "";
    this.claimant = data.claimant || "";
    this.defendant = data.defendant || "";
    this.amount = data.amount || 0;
    this.status = data.status || "";
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
    this.user_id = data.user_id || "";
  }
}
