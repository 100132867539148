import SelectForm from "../Forms/SelectForm";
import FormText from "../Forms/FormText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

const Repeater = ({
  options,
  sections,
  handleTextChange,
  handleSelectChange,
  removeSection,
  controlId,
  textname,
  selecteplaceholder,
  textplaceholder,
  textRequired,
  hasExplanation,
  textContent,
}) => {
  return (
    <>
      {sections.length > 0 && ( // Check if sections array is not empty
        <>
          {sections.map((section, index) => (
            <Row key={section.id} className="row-hover align-items-start">
              <Col md={6}>
                <SelectForm
                  options={options}
                  defaultVal={section.selectValue}
                  controlId={`${controlId}${section.id}`}
                  name={`${controlId}${section.id}`}
                  placeholder={selecteplaceholder}
                  onChange={(event) => handleSelectChange(section.id, event)}
                  hasExplanation={hasExplanation}
                  textContent={textContent}
                />
              </Col>
              <Col>
                <FormText
                  type="text"
                  name={`${textname}${section.id}`}
                  label={textplaceholder}
                  placeholder={textplaceholder}
                  value={section.textValue}
                  isRequired={textRequired}
                  hideLabel={true}
                  onChange={(event) => handleTextChange(section.id, event)}
                  key={`${textname}${section.id}`}
                />
              </Col>

              {index !== 0 && (
                <Col xs="auto" className="text-center">
                  <Button
                    variant="link"
                    className="p-0 mb-3 h-auto remove-section"
                    onClick={() => removeSection(section.id)}
                  >
                    <X size="25px" color="264687" />
                    <span className="visually-hidden">Remove</span>
                  </Button>
                </Col>
              )}
            </Row>
          ))}
        </>
      )}
    </>
  );
};

export default Repeater;
