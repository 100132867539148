import React, { useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import logo from "../../assets/empty-state.png"; // Keep your existing logo
const LoadingPage = () => {

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100 bg-light"
    >
      <Row className="text-center">
        <Col>
          {/* Branding Logo */}
          <img
            src={logo} // Replace with your actual logo path
            alt="Icanepo logo"
            className="img-fluid mb-4"
            style={{ width: "150px" }} // Adjust size as needed
          />

          <h1 className="text-primary">Redirecting...</h1>
          <p className="text-secondary mb-3">
            Please wait while we securely log you in.
          </p>

          <p className="text-primary">
            If you are not redirected within a few seconds,{" "}
            <a href="/tasks">
              click here
            </a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingPage;
