interface Document {
  id: string; // Unique identifier for the case document (UUID)
  name: string; // Name of the case document
  type: string; // Type of the case document
}

interface CaseHistoryData {
  id: string; // Unique identifier for the history event (UUID)
  created_by: string; // User who created the history
  created_at: string; // Date and time when the event occurred (ISO 8601)
  event_code: string; // Technical identifier of the event
  event_short_name: string; // Human-readable short name of the event
  event_long_name: string; // Human-readable long name of the event
  case_version: string; // Assigned version of the case
  documents?: Document[]; // List of documents related to this history event
}

export class CaseHistory {
  id: string;
  created_by: string;
  created_at: string;
  event_code: string;
  event_short_name: string;
  event_long_name: string;
  case_version: string;
  documents?: Document[];

  constructor(data: CaseHistoryData) {
    this.id = data.id;
    this.created_by = data.created_by;
    this.created_at = data.created_at;
    this.event_code = data.event_code;
    this.event_short_name = data.event_short_name;
    this.event_long_name = data.event_long_name;
    this.case_version = data.case_version;
    this.documents = data.documents;
  }
}