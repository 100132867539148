import SectionTitle from "../../../../../components/SectionTitle/SectionTitle";
import CommentsList from "../../../../../components/ScrollableBox/Sidebar/TasksContents/Comments/CommentsList";
import "./DossierContent.scss";

const CommentsPDF = ({ setFormData, user, value, formData,comments }) => {
  const handleEditorChange = (value) => {
    setFormData({ ...formData, content: value });
  };
  return (
    <div className="comments-section">
      <div className="title-wrapper">
        <SectionTitle
          classn="h3 text-primary title-underline"
          label="Comments"
        />
      </div>
      <CommentsList
        data={comments}
        user={user}
        value={value}
        handleEditorChange={handleEditorChange}
        label="documentcomments"
      />
    </div>
  );
};

export default CommentsPDF;
