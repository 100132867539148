import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectForm from "../Forms/SelectForm";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

import TableRepeater from "../FormEditor/Sections/Table";

const DefendantRepeater = ({
  sections,
  companies,
  handleDefendantChange,
  curencies,
  removeSection,
}) => {
  return (
    <>
      {sections.length && companies.length && curencies.length && (
        <>
          {sections.map((section, index) => (
            <Row key={section.id}>
              <Col
                xs={12}
                className="border-bottom d-flex justify-content-between border-top border-1 border-secondary py-3"
              >
                <h2 className="text-primary fw-semibold mb-0">
                  Defendant {index + 1}
                </h2>
                {index != 0 && (
                  <Button
                    variant="link"
                    className="p-0 h-auto remove-section"
                    onClick={() => removeSection(section.id)}
                  >
                    <X size="25px" color="264687"/>
                    <span className="visually-hidden">Remove</span>
                  </Button>
                )}
              </Col>
              <Col
                xs={12}
                className="border-bottom border-1 border-secondary pt-3"
              >
                <SelectForm
                  options={companies}
                  defaultVal={section.defendant.id}
                  controlId={`defendant_${section.id}`}
                  name={`defendant_${section.id}`}
                  placeholder="Select company/organization"
                  label="Surname, first name/Name of company or organization"
                  onChange={(event) => {
                    handleDefendantChange(
                      section.id,
                      event.value,
                      "defendant",
                      "id"
                    );
                    handleDefendantChange(
                      section.id,
                      event.label,
                      "defendant",
                      "name"
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="px-0">
                <TableRepeater
                  curencies={curencies}
                  section={section}
                  handleDefendantChange={handleDefendantChange}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
};

export default DefendantRepeater;
