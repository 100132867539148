import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
const AccordionCard = ({
  eventKey,
  title,
  body,
  isDisabled,
  custom,
  isOpen,
  customHeader,
}) => {
  return (
    <Accordion
      defaultActiveKey={isOpen ? eventKey : null}
      className={`${isDisabled ? "disabled" : ""} ${custom}`}
    >
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>{title}</Accordion.Header>
        {customHeader && <>{customHeader}</>}
        <Accordion.Body>
          <Container fluid>
            <Row>{body}</Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionCard;
