import Form from "react-bootstrap/Form";

const RadiosForm = ({
  reverse = false,
  label,
  name,
  options = [], // Array of options for radio buttons
  selectedValue,
  disabled,
  onChange,
  hideLabel,
  isRequired,
  customClass,
  layout = "horizontal",
}) => {
  return (
    <div
      className={`form-wrapper form-text-element mb-0 radio-buttons ${
        layout !== "horizontal" ? layout : ""
      }`}
    >
      {/* <Form.Label
        // htmlFor={name}
        className={`${customClass ? customClass : ""} ${
          hideLabel ? "visually-hidden" : ""
        }`}
      >
        {label}
      </Form.Label> */}

      <div>
        {options.map((option, index) => (
          <Form.Check
            reverse={reverse}
            inline
            key={index}
            type="radio"
            label={option.label}
            name={name + index}
            id={name + index}
            value={option.value}
            checked={selectedValue === option.value}
            disabled={disabled}
            onChange={onChange}
            required={isRequired}
          />
        ))}
      </div>
    </div>
  );
};

export default RadiosForm;
