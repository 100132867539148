import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";

const FormSubmit = ({ cancelLabel, handleCancel, submitLabel }) => {
  return (
    <Row className="justify-content-end submit-wrapper">
      <Col xs="auto">
        <Button variant="secondary" onClick={handleCancel} className="ml-2">
          {cancelLabel}
        </Button>
      </Col>
      <Col xs="auto">
        <Button variant="primary" type="submit">
          {submitLabel}
        </Button>
      </Col>
    </Row>
  );
};

export default FormSubmit;
