import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { Wallet } from "react-iconly";
import { useNavigate } from "react-router-dom";
import IconComponent from "../../../IconComponent/IconComponent";
import SelectComponent from "../../../Select/Select";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import FormText from "../../../Forms/FormText";
import casesJSON from "../../../../../public/demo/cases.json";

const TaskTopBar = ({ getRow, data, type, handleChange, error }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [cases, setCases] = useState(null);
  const [state, setState] = useState(false);
  const navigateToNewCase = (taskid) => {
    navigate("/cases/create/" + taskid);
  };

  useEffect(() => {
    if (type == "task" && data != null) {
      //if (data.id != getRow) {
      //setState(false);
      //} else {
      setState(true);
      //}
    } else {
      if (data != null) {
        setState(true);
      }
      setCases(casesJSON);
      setOptions(
        casesJSON.map((item) => ({
          value: item.case_id,
          label: item.case_num,
        }))
      );
    }

   
  }, [getRow, data, type]);
  return (
    <>
      {data != null && state && (
        <Col md="auto" xs={10}>
          {data.related_case == null && type != "createnote" ? (
            <>
              {type == "createtask" ? (
                <div className="d-flex align-items-start">
                  <IconComponent
                    icon={<Wallet label="Wallet" primaryColor="#264687" />}
                    text="Case:"
                  />
                  {type == "createtask" && id != null ? (
                    <FormText
                      type="text"
                      name={`caseStatus_${type}`}
                      label="Select case"
                      pattern="^[\s\S]*$"
                      placeholder=""
                      value={data.caseNumber}
                      isRequired={false}
                      hideLabel={true}
                      onChange={handleChange}
                      key={`caseStatus_${type}`}
                      disabled={true}
                    />
                  ) : (
                    <SelectComponent
                      handleChange={handleChange}
                      options={options}
                      controlId="caseStatusCreate"
                      name="case_id"
                      placeholder="Select case"
                      defaultVal={id != null ? String(id) : ""}
                      disabled={
                        type == "createtask" && id != null ? true : false
                      }
                      error={error}
                      isRequired={false}
                      clearSetting={true}
                    />
                  )}
                </div>
              ) : (
                <>
                  <Button
                    variant="primary"
                    onClick={() => navigateToNewCase(getRow)}
                  >
                    Create new case
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <IconComponent
                  icon={<Wallet label="Wallet" primaryColor="#264687" />}
                  text="Case:"
                />
                {/* <SelectComponent
                    handleChange={handleChange}
                    options={options}
                    controlId={`caseStatus_${type}`}
                    name="case_id"
                    placeholder="Select case"
                    defaultVal={id != null ? String(id) : data.case_id}
                    isRequired={true}
                    error={error}
                    disabled={
                      type == "createtask" || type == "createnote"
                        ? true
                        : false
                    }
                  /> */}
                <FormText
                  type="text"
                  name={`caseStatus_${type}`}
                  label="Select case"
                  pattern="^[\s\S]*$"
                  placeholder=""
                  value={data.caseNumber}
                  isRequired={false}
                  hideLabel={true}
                  onChange={handleChange}
                  key={`caseStatus_${type}`}
                  disabled={true}
                />
              </div>
            </>
          )}
        </Col>
      )}
    </>
  );
};

export default TaskTopBar;
