import ListPDF from "./ListPDF";
const ListItem = ({ item, divRef, docs,hasActions,hasIcons,casedat }) => {

  return (
    <div className="item-list">
      <div className="ref-number">
        <span>{item.ref_num}</span>
        {item.is_signed && <span className="ref-doc">ref: 2347jfk</span>}
      </div>
      <div className="pdf-wrapper">
        <ListPDF item={item} divRef={divRef} docs={docs} hasActions={hasActions} hasIcons={hasIcons} casedat={casedat}/>
      </div>
    </div>
  );
};

export default ListItem;
