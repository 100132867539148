import "./SubNavigation.scss";

const SubNavigation = ({ activeTab, setActiveTab,isdisabled }) => {
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="subnavigation">
      <ul className="nav nav-tabs flex-nowrap">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            Details
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabChange(2)}
            disabled={isdisabled}
          >
            Dossier
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabChange(3)}
            disabled={isdisabled}
          >
            Form Editor
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SubNavigation;
