import { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/empty-state.png"; // Keep your existing logo
import ScrollableBox from "../../components/ScrollableBox/ScrollableBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import BtnSidebar from "../../components/BtnSidebar/BtnSidebar";
import Title from "../../components/Tables/Title/Title";
import { fetchAllTasks } from "../../services/apiService";
import "./Tasks.scss";

function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null); // Error state
  const [loading, setLoading] = useState(true); // Loading state
  const [sidebarPosition, setSidebarPosition] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  // Search handler
  const handleSearch = (query) => {
    setSearchQuery(query); // Update search query state
  };

  const divRef = useRef(null);

  useEffect(() => {
    // Function to get the bottom position of the div
    const getBottomPosition = () => {
      if (divRef.current) {
        const { bottom } = divRef.current.getBoundingClientRect();
        setSidebarPosition(bottom);
      }
    };

    // Call the function when component mounts
    setTimeout(() => {
      getBottomPosition();
    }, 1000);

    return () => {
      // Cleanup if needed
    };
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true); // Start loading state
      try {
        const taskObjects = await fetchAllTasks(); // Use the API service to fetch tasks
        setTasks(taskObjects); // Set tasks state with fetched tasks
      } catch (err) {
        console.log("Response headers:", err);
        setError("Failed to fetch tasks."); // Set error message
      } finally {
        setLoading(false); // Set loading to false regardless of outcome
      }
    };
    fetchTasks();
  }, []);

  const filteredTasks = tasks.filter((task) => {
    const search = searchQuery.toLowerCase();
    return (
      task.title?.toLowerCase().includes(search) ||
      task.related_case?.caseNumber?.toString().toLowerCase().includes(search) ||
      task.related_case?.department?.toLowerCase().includes(search) ||
      task.related_case?.claimant?.toLowerCase().includes(search) ||
      task.related_case?.defendant?.toLowerCase().includes(search) ||
      task.related_case?.amount?.toString().toLowerCase().includes(search)
    );
  });

  return (
    <>
      {loading && <div>Loading tasks...</div>} {/* Loading state UI */}
      {error && <div>{error}</div>} {/* Error state UI */}
      {tasks != null && !loading && (
        <>
          {!tasks.length && <Title title="My tasks" customClass="visually-hidden" />}
          <Container
            fluid
            className="container-wrapper border-bottom "
            ref={divRef}
          >
            <Row>
              <Col xs={12}>
                <div className="searchbar-button-wrapper">
                  <div className="w-100"></div>
                  {tasks.length > 0 && (
                    <div className="searchbar-wrapper">
                      <SearchBar onSearch={handleSearch} />
                    </div>
                  )}
                  <div className="w-100">
                    <BtnSidebar
                      state={null}
                      type="createtask"
                      divRef={divRef}
                      label="Create new task"
                      disabled={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {tasks.length > 0 ? (
            <Container fluid className="add-scroll">
              <Row>
                <Col xs={12}>
                  <ScrollableBox
                    tasks={filteredTasks}
                    sidebarPosition={sidebarPosition}
                    setTasks={setTasks}
                  />
                </Col>
              </Row>
            </Container>
          ) : (
            <div className="empty-state position-relative">
              <Container fluid>
                <Row className="justify-content-center align-items-center">
                  <Col xs="auto">
                    <img
                      src={logo}
                      className="d-inline-block align-top img-fluid"
                      alt="Icanepo Empty State"
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Tasks;
