import Select from "react-select";
import { useState } from "react";
import { FormLabel } from "react-bootstrap";
const Filters = ({
  options,
  defaultVal,
  handleChange,
  controlId,
  name,
  placeholder,
  clearSetting = false
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isClearable] = useState(clearSetting);
  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the menu input text
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#DBE5FB",
      borderRadius: "6px",
      color: "#335BAB",
      minWidth: "150px",
      fontWeight: "600",
      width: "fit-content",
      marginLeft: "auto",
      boxShadow: "none",
      border: "none",
      borderBottomLeftRadius: menuIsOpen ? 0 : "6px",
      borderBottomRightRadius: menuIsOpen ? 0 : "6px",
      borderBottomWidth: menuIsOpen ? "0px" : "1px",
      "&:hover": {
        borderColor: "#94A9D4", // Change border color on hover
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the dropdown arrow
      "&:hover": {
        color: "#335BAB", // Change border color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1rem",
      color: "#335BAB", // Change the color of the placeholder text here
      whiteSpace: "nowrap", // Prevent the placeholder text from wrapping
      overflow: "hidden", // Hide overflowing text
      textOverflow: "ellipsis", // Add ellipsis when text overflows
      width:"100%",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0px",
      marginBottom: "0px",
      right: 0,
      minWidth: "150px",
      width: "fit-content",
      boxShadow: "0px 4px 20px 0px rgba(51, 91, 171, 0.15)",
      backgroundColor: "#FFF",
      fontSize: "1rem",
      color: "#335BAB",
      border: "none",
      fontWeight: "600",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#264687" : "transparent", // Background color of the selected option in the menu
    }),
    // Add more custom styles as needed
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const defaultOption = options.find((option) => option.value === defaultVal);
  return (
    <>
      <FormLabel htmlFor={controlId} className="visually-hidden">
        {name}
      </FormLabel>
      <Select
        controlId={controlId}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultOption}
        options={options}
        inputId={controlId}
        isClearable={isClearable}
        aria-labelledby={controlId}
        className="w-auto ms-auto order-md-1 order-0 "
        onChange={(value) => handleChange(name, value.value)}
        styles={customStyles}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        classNames={{
          menu: () => "custom-menu-class",
        }}
      />
    </>
  );
};

export default Filters;
