import {CovertDates} from "../../../../../utils/func";

const HistoryItem = (props) => {
  return (
    <div className="history-item">
      <div className="item item-date">
        <span className="item-label">Date</span>
        <span className="item-text"><CovertDates date={props.date} /></span>
      </div>
      <div className="item item-event">
        <span className="item-label">Event</span>
        <span className="item-text">{props.event}</span>
      </div>
      <div className="item item-person">
        <span className="item-label">Person</span>
        <span className="item-text">{props.person}</span>
      </div>
    </div>
  );
};

export default HistoryItem;