import { isAuthenticated } from "../../utils/keykloak";

const Protected = ({ Component }) => {
  const isAuth = isAuthenticated();
  if (!isAuth) {
    return (
      <>
        <h1>No Authentication</h1>
      </>
    );
  }

  return <Component />;
};

export default Protected;
