import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HistoryItem from "./HistoryItem";

const HistoryList = (props) => {
  return (
    <Container fluid className="section-border-bottom">
      <Row>
        <>
          {props.data && props.data.length ? (
            <>
              {props.data.map((dat, index) => (
                <Col className="history-item-col" xs={12} key={index}>
                  <HistoryItem
                    id={dat.id}
                    date={dat.date}
                    event={dat.event}
                    person={dat.createdBy}
                  />
                </Col>
              ))}
            </>
          ) : (
            <Col xs={12} className="text-center mt-30 pb-20">
              <span>No events</span>
            </Col>
          )}
        </>
      </Row>
    </Container>
  );
};

export default HistoryList;
