import Form from "react-bootstrap/Form";

const FormTextArea = ({
  label,
  type,
  name,
  value,
  disabled,
  placeholder,
  onChange,
  hideLabel,
  isRequired,
  customClass,
  layout = "horizontal",
  rowNum = 3, // Default rows if not specified
}) => {
  return (
    <div
      className={`form-wrapper form-text-element ${
        layout !== "horizontal" ? layout : ""
      }`}
    >
      <Form.Group>
        <Form.Label
          htmlFor={name}
          className={`${customClass ? customClass : ""} ${
            hideLabel ? "visually-hidden" : ""
          }`}
        >
          {label}
        </Form.Label>
        <Form.Control
          className="h-auto"
          id={name}
          as="textarea"
          rows={rowNum}
          required={isRequired}
          disabled={disabled}
          defaultValue={value}
          placeholder={placeholder}
          onBlur={onChange}
        />
      </Form.Group>
    </div>
  );
};

export default FormTextArea;
