import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Calendar } from "react-iconly";

import Card from "../../../Card/Card";
import IconComponent from "../../../IconComponent/IconComponent";
import CalendarComponent from "../../../Calendar/Calendar";
import FormText from "../../../Forms/FormText";


const FormG = ({ id, onInputChange, formData }) => {
  const FormDCardHeader = ({ title, customCls }) => {
    return (
      <Container fluid>
        <Row className="py-3">
          <Col xs={12}>
            <h3 className={`${customCls} text-primary mb-0 h4`}>{title}</h3>
          </Col>
        </Row>
      </Container>
    );
  };

  const FormCardBody = ({ content }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>
            <p className="text-primary fw-semibold">{content}</p>
          </Col>
        </Row>
      </Container>
    );
  };

  const formDCardBody = (formData) => {
    return (
      <Container fluid >
        <Row className="align-items-center border-top border-3 border-secondary">
          <Col
            md={5}
            className="bg-fadeblue py-3 border-end border-3 border-warning"
          >
            <Row className="align-items-center">
              <Col xl={5}>
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text="issued on:"
                  customClass="remove-m"
                />
              </Col>
              <Col xxl={6} xs={12}>
                <CalendarComponent
                label="issueon_date"
                  handleChange={(date, value) =>
                    onInputChange("issued_on", date, value)
                  }
                  date={
                    formData && formData.issued_on
                      ? formData.issued_on
                      : ""
                  }
                  error={false}
                />
              </Col>
            </Row>
          </Col>
          <Col md={7} className="formd-layout-dates">
            <FormText
              type="text"
              name="against"
              isRequired={false}
              hideLabel={false}
              customClass="fw-normal"
              value={
                formData && formData.against ? formData.against : ""
              }
              label="against:"
              onChange={(event) =>
                onInputChange("against", "against", event.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="align-items-center border-top border-3 border-secondary">
          <Col
            md={5}
            className="bg-fadeblue py-3 border-end border-3 border-warning"
          >
            <Row className="align-items-center">
              <Col xl={5}>
                <IconComponent
                  icon={<Calendar label="Calendar" primaryColor="#264687" />}
                  text="and served on:"
                  customClass="remove-m"
                />
              </Col>
              <Col xxl={6} xs={12} className="align-self-end">
                <CalendarComponent
                label="servedon_date"
                  handleChange={(date, value) =>
                    onInputChange("served_on", date, value)
                  }
                  date={
                    formData && formData.served_on
                      ? formData.served_on
                      : ""
                  }
                  error={false}
                />
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <p className="text-primary mb-0 span">
              is enforceable in accordance with Article 18 of Regulation (EC) No
              1896/2006.
            </p>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <div className="section">
        <Card
          header={
            <FormDCardHeader
              title="The court hereby declares that the attached European order for payment."
              customCls="fw-semibold"
            />
          }
          body={formDCardBody(formData)}
        />
      </div>
      <div className="section">
        <Card
          header={
            <FormDCardHeader
              title="IMPORTANT INFORMATION"
              customCls="fw-semibold h2 text-center"
            />
          }
          body={
            <FormCardBody content="This European order for payment is automatically enforceable in all Member States of the European Union except Denmark, without the need for an additional declaration of enforceability in the Member State where enforcement is sought and without any possibility of opposing its recognition. The enforcement procedures are governed by the law of the Member State of enforcement, except where the Regulation provides otherwise." />
          }
        />
      </div>
    </>
  );
};
export default FormG;
