import SectionTitle from "../../../../../components/SectionTitle/SectionTitle";
import ListsPDF from "../../../../../components/ListsPDF/ListsPDF";
const Documnets = ({
  data,
  setActivePDF,
  activePDFId,
  setActivePDFId,
  setDossier,
  divRef,
  label,
  hasActions,
  hasupload,
  hasIcons,
  type,
  handleClick,
  casedat
}) => {
  return (
    <>
      <SectionTitle classn="h1 text-primary title-underline" label={label} />
      <ListsPDF
        data={data}
        setActivePDF={setActivePDF}
        activePDFId={activePDFId}
        setActivePDFId={setActivePDFId}
        setDossier={setDossier}
        divRef={divRef}
        hasActions={hasActions}
        hasupload={hasupload}
        hasIcons={hasIcons}
        type={type}
        handleClick={handleClick}
        casedat={casedat}
      />
    </>
  );
};

export default Documnets;
