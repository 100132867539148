import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";

import Repeater from "../../../Repeater/Repeater";
import Card from "../../../Card/Card";
import langcode from "../../../../../public/demo/lang_codes.json";
import formbcode from "../../../../../public/demo/formb_codes.json";
import DueDate from "../DueDate";
import FormCardHeader from "../CardHeader";

const FormB = ({
  id,
  onInputChange,
  langSections,
  setLangSections,
  formCodesSections,
  setFormCodesSections,
  langCodes,
  formCodes,
  setlangCodes,
  setFormCodes,
  formData,
  addSection,
  removeSection,
  handleSelectChange,
  handleTextChange,
}) => {
  useEffect(() => {
    setlangCodes(langcode);
    setFormCodes(formbcode);
  }, []);

  

  const formBSection = (formData) => {
    return (
      <DueDate
        title="Following the examination of your application for a European order
      for payment, please complete and/or rectify the attached
      application as indicated below as soon as possible and in any
      event by:"
        onInputChange={onInputChange}
        value={formData && formData.due_date ? formData.due_date : ""}
        content={[
          "Your initial application should be completed and/or rectified inthe language or in one of the languages accepted by the courtseised.",
          "The court will reject the application, under the conditionsprovided for in the Regulation, if you fail to complete and/orrectify the application within the time limit set out above.",
        ]}
      />
    );
  };

  const FormBRepeater = ({
    options,
    sections,
    handleTextChange,
    handleSelectChange,
    setSection,
    controlId,
    textname,
    selecteplaceholder,
    textplaceholder,
    type
  }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Repeater
              options={options}
              sections={sections}
              handleTextChange={handleTextChange}
              handleSelectChange={handleSelectChange}
              controlId={controlId}
              textname={textname}
              selecteplaceholder={selecteplaceholder}
              textplaceholder={textplaceholder}
              removeSection={(id) => removeSection(id, setSection, sections,type)}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <div className="section">
        <Card body={formBSection(formData)} />
      </div>
      <div className="section">
        {langCodes != null && (
          <Card
            header={
              <FormCardHeader
                title="Your application has not been filled in, in the correct language. Please fill it in in one of the following languages."
                addAction={() => addSection(setLangSections, langSections)}
              />
            }
            body={
              <FormBRepeater
                options={langCodes}
                sections={langSections}
                type="lang_code"
                handleTextChange={(id, event) =>
                  handleTextChange(
                    "lang_code",
                    id,
                    event,
                    setLangSections,
                    langSections
                  )
                }
                handleSelectChange={(id, event) =>
                  handleSelectChange(
                    "lang_code",
                    id,
                    event,
                    setLangSections,
                    langSections
                  )
                }
                setSection={setLangSections}
                controlId="lang_"
                selecteplaceholder="Select language code"
                textRequired={false}
                textname="code_"
                textplaceholder="Language specification (only for code 20)"
                removeSection={(id) =>
                  removeSection(id, setLangSections, langSections, "lang_code")
                }
              />
            }
          />
        )}
      </div>
      <div className="section">
        {formCodes != null && (
          <Card
            header={
              <FormCardHeader
                title="The following items  must be completed and/or rectified"
                addAction={() =>
                  addSection(setFormCodesSections, formCodesSections)
                }
              />
            }
            body={
              <FormBRepeater
                options={formCodes}
                sections={formCodesSections}
                type="form_code"
                handleTextChange={(id, event) =>
                  handleTextChange(
                    "form_code",
                    id,
                    event,
                    setFormCodesSections,
                    formCodesSections
                  )
                }
                handleSelectChange={(id, event) =>
                  handleSelectChange(
                    "form_code",
                    id,
                    event,
                    setFormCodesSections,
                    formCodesSections
                  )
                }
                setSection={setFormCodesSections}
                controlId="formcode_"
                selecteplaceholder="Select code"
                textname="specification_"
                textRequired={false}
                textplaceholder="Please specify"
                removeSection={(id) =>
                  removeSection(
                    id,
                    setFormCodesSections,
                    formCodesSections,
                    "formcode_"
                  )
                }
              />
            }
          />
        )}
      </div>
    </>
  );
};
export default FormB;
