import { useState } from "react";

import { CovertDates } from "../../../../../utils/func";
import InfoCard from "../../../../../components/InfoCard/InfoCard";

const InfoPDF = ({ activePDFId }) => {
  const [metaDataPDF, setMetaDataPDF] = useState({
    filename: "Form A",
    ref_num: "F98459",
    created_at: "2023-12-02 17:19:00",
    updated_at: "2023-12-02 17:19:00",
    craeted_by: "Stefania Giagkou",
  });
  return (
    <>
      <InfoCard label="File name" data={metaDataPDF.filename} />
      <InfoCard label="Ref No." data={metaDataPDF.ref_num} />
      <InfoCard label="Date" data={<CovertDates date={metaDataPDF.created_at} />} />
    </>
  );
};

export default InfoPDF;
