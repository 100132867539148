// Define the AllowedOrigins interface for allowed origins property
interface AllowedOrigins {
  origins: string[]; // List of allowed origins
}

// Define the RealmAccess interface
interface RealmAccess {
  roles: string[]; // List of roles the user has in the realm
}

// Define the ResourceAccess interface
interface ResourceAccess {
  account: {
    roles: string[]; // List of roles the user has in the account resource
  };
}

// Define the complete UserModel class
export class UserModel {
  acr: string; // Authentication context class reference
  allowedOrigins: AllowedOrigins; // Allowed origins for the user
  aud: string; // Audience claim
  authTime: number; // Time of authentication
  azp: string; // Authorized party
  email: string; // User's email
  emailVerified: boolean; // Whether the user's email is verified
  exp: number; // Expiration time of the token
  familyName: string; // User's family name
  givenName: string; // User's given name
  iat: number; // Issued at time
  iss: string; // Issuer identifier
  jti: string; // JWT ID
  name: string; // Full name of the user
  nonce: string; // OIDC nonce
  preferredUsername: string; // Preferred username of the user
  realmAccess: RealmAccess; // Realm access roles
  resourceAccess: ResourceAccess; // Resource access roles
  scope: string; // Scope of the token
  sessionState: string; // Session state identifier
  sid: string; // Session ID
  sub: string; // Subject identifier
  typ: string; // Token type

  // Constructor to initialize the user model
  constructor(data: {
    acr: string;
    allowed_origins: string[];
    aud: string;
    auth_time: number;
    azp: string;
    email: string;
    email_verified: boolean;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    iss: string;
    jti: string;
    name: string;
    nonce: string;
    preferred_username: string;
    realm_access: { roles: string[] };
    resource_access: { account: { roles: string[] } };
    scope: string;
    session_state: string;
    sid: string;
    sub: string;
    typ: string;
  }) {
    this.acr = data.acr;
    this.allowedOrigins = { origins: data.allowed_origins };
    this.aud = data.aud;
    this.authTime = data.auth_time;
    this.azp = data.azp;
    this.email = data.email;
    this.emailVerified = data.email_verified;
    this.exp = data.exp;
    this.familyName = data.family_name;
    this.givenName = data.given_name;
    this.iat = data.iat;
    this.iss = data.iss;
    this.jti = data.jti;
    this.name = data.name;
    this.nonce = data.nonce;
    this.preferredUsername = data.preferred_username;
    this.realmAccess = { roles: data.realm_access.roles };
    this.resourceAccess = { account: { roles: data.resource_access.account.roles } };
    this.scope = data.scope;
    this.sessionState = data.session_state;
    this.sid = data.sid;
    this.sub = data.sub;
    this.typ = data.typ;
  }

  // Method to get a summary of the user
  getSummary(): string {
    return `User: ${this.name}, Email: ${this.email}, Roles: ${this.realmAccess.roles.join(', ')}`;
  }

  // Method to format the auth time
  getFormattedAuthTime(): string {
    const date = new Date(this.authTime * 1000); // Convert from UNIX timestamp
    return date.toLocaleString(); // Customize the date format as needed
  }
}
