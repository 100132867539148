import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";

import SectionHeader from "../../../SectionTitle/SectionTitle";
import SectionTitle from "../TasksContents/SectionTitle";
import FormDescription from "../TasksContents/FormDescription";
import FilesList from "../TasksContents/FilesList";
import FormSubmit from "./FormSubmit/FormSubmit";
import SelectComponent from "../../../Select/Select";
const CreateNote = ({
  id,
  setSideNavExpanded,
  data,
  user,
  setData,
  handleChange,
  formData,
  setFormData,
  handleSubmit,
  handleFileChange,
  handleCancel,
  options,
  caseData
}) => {
  const [note, setNote] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [alldocuments, setAllDocuments] = useState([]);
  const [errorRelated, seterrorRelated] = useState(false);
  const [errorDescription, seterrorDescription] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setNote(caseData);
      setFormData({
        files: [],
      });
      setData(caseData);
      if (options && options.length) {
        setAllDocuments(options);
        setDocuments(
          options.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
    }, 500);
  }, [id, setNote, setData, setFormData]);

  const handleChangeRelated = (name, value) => {
    console.log(name, value)
    handleChange(name, value);
    setSelectedDocuments([]);
    var tmp = alldocuments;
    const selectedRelatedArray = value.map((id) =>
      tmp.find((related) => related.id === id)
    );

    // Update the selectedParticipants state with the found participants
    setSelectedDocuments((prevRelated) => [
      ...prevRelated,
      ...selectedRelatedArray,
    ]);
    seterrorRelated(false);
  };

  const resetForm = () => {
    //setNote(null);
    setSelectedDocuments([]);
    //setDocuments([]);
  };

  const handleSubmitNote = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      if (selectedDocuments && !selectedDocuments.length) {
        seterrorRelated(true);
      }
      e.preventDefault();
      e.stopPropagation();
    } else {
      seterrorRelated(false);
      handleSubmit(e);
      resetForm();
    }
  };

  const handleCancelNote = (e) => {
    handleCancel(e);
    resetForm();
  };

  return (
    <Form noValidate className="sidebar-form" onSubmit={handleSubmitNote}>
      {note != null && data != null && formData && (
        <>
          <SectionHeader label="Internal note" classn="eventline-title" />
          <SectionTitle sectionTitle="Description" />
          <FormDescription
            description={note.description}
            handleChange={handleChange}
            error={errorDescription}
            label="notedescription"
          />
          <SectionTitle sectionTitle="Related files" />
          <div className="select-component">
            <SelectComponent
              handleChange={handleChangeRelated}
              options={documents}
              controlId="relatedStatusNote"
              name="related"
              placeholder="Add a reference"
              defaultVal=""
              isMulti={true}
              disabled={false}
              closeMenuOnSelect={false}
              error={errorRelated}
              isRequired={false}
            />
          </div>
          <FilesList
            data={note.related}
            formData={selectedDocuments}
            handleFileChange={handleFileChange}
          />
          <Container fluid>
            <FormSubmit
              cancelLabel="Cancel"
              handleCancel={handleCancelNote}
              submitLabel="Complete & File"
            />
          </Container>
        </>
      )}
    </Form>
  );
};

export default CreateNote;
