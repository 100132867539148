import { useState, useEffect } from "react";
import SectionTitle from "../SectionTitle";
import HistoryList from "./HistoryList";
import { fetchTaskHistory } from "../../../../../services/apiService";

const History = ({ id, type }) => {
  const [history, setHistory] = useState([]); // Initialize as an empty array
  const [error, setError] = useState(null); // For error handling
  const [loading, setLoading] = useState(false); // For loading state

  useEffect(() => {
    const fetchHistory = async () => {
      if (type !== "createtask") {
        setLoading(true); // Start loading
        setError(null); // Reset error state
        try {
          const taskHistoryResponse = await fetchTaskHistory(id); // Use the API service function
          setHistory(taskHistoryResponse.history); // Directly set history from the model
        } catch (err) {
          console.error("Error fetching history:", err);
          const errorMessage =
            err.response?.data?.message || "Failed to fetch history.";
          setError(errorMessage); // Set error message from response or fallback
        } finally {
          setLoading(false); // Stop loading
        }
      }
    };

    fetchHistory();
  }, [id, type]); // Re-fetch when `id` or `type` changes

  return (
    <>
      <SectionTitle sectionTitle="History" />
      {loading && <div>Loading history...</div>} {/* Show loading message */}
      {error && <div className="error-message">{error}</div>}
      {/* Display error message if exists */}
      <HistoryList data={history} />
      {/* Pass the fetched history data to HistoryList */}
    </>
  );
};

export default History;
