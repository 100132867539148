import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import FormMessage from "../Forms/FormMessage";
const FormText = ({
  label,
  type,
  name,
  value,
  disabled,
  placeholder,
  onChange,
  hideLabel,
  isRequired,
  customClass,
  layout = "horizontal",
  autoComplete = "off",
  inputCustomClass = "",
  pattern = null,
}) => {
  const [error, setError] = useState("");
  const [val,setVal] = useState("");
  useEffect(() => {
    setVal(value);
  }, [value]);
  const handleBlur = (event) => {
    const regex = new RegExp(pattern);
    let tmp2 = event.target.value;
    if (!regex.test(tmp2)) {
      setError("Invalid format");
    } else {
      setError("");
      onChange(event); // Call the passed-in onChange handler
    }
  };

  return (
    <div
      className={`form-wrapper form-text-element ${
        layout !== "horizontal" ? layout : ""
      }`}
    >
      <Form.Label
        htmlFor={name}
        className={`${customClass ? customClass : ""} ${
          hideLabel ? "visually-hidden" : ""
        }`}
      >
        {label}
      </Form.Label>
      <Form.Control
        type={type}
        id={name}
        defaultValue={val}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={pattern != null ? handleBlur : onChange}
        required={isRequired}
        autoComplete={autoComplete}
        className={`${inputCustomClass ? inputCustomClass : ""}`}
        pattern={pattern}
      />
      <FormMessage message={error} error={error} />
    </div>
  );
};

export default FormText;
