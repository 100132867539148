import { ExtractInitials } from "../../../../../utils/func";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { useEffect, useRef } from "react";
// import MDEditor, { commands } from '@uiw/react-md-editor';
import { Form } from "react-bootstrap";

const CommentEditor = ({ user, value, handleEditorChange, label }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editorElement = editorRef.current;

    if (editorElement) {
      const waitForCodeMirror = setInterval(() => {
        const codeMirrorContainer = editorElement.querySelector(".CodeMirror");

        if (codeMirrorContainer) {
          clearInterval(waitForCodeMirror); // Stop checking once found

          // Find the textarea element inside the CodeMirror container
          const textarea = codeMirrorContainer.querySelector("textarea");

          if (textarea) {
            // Set the custom ID for the textarea
            const customTextareaId = "custom_" + label;
            textarea.id = customTextareaId; // Assign custom ID to textarea

            // Create a visually hidden label and link it to the textarea
            const setlabel = document.createElement("label");
            setlabel.setAttribute("for", customTextareaId); // Link setlabel to the textarea by ID
            setlabel.textContent = "Enter your content:"; // You can set the label's text as needed

            // Add the label above the textarea
            // Instead of insertBefore, we'll append it to the parent of the textarea (codeMirrorContainer)
            codeMirrorContainer.prepend(setlabel); // This will insert the label as the first child of the container

            // Make the label visually hidden but accessible to screen readers
            setlabel.classList.add("visually-hidden");
          }
        }
      }, 100); // Check every 100ms

      // Optionally, clear the interval after some time to avoid an infinite loop
      setTimeout(() => clearInterval(waitForCodeMirror), 5000);
    }
  }, []);
  return (
    <>
      <div className="comments-item w-100 mb-30">
        <div className="avatar-text">
          <span>
            <ExtractInitials fullName={user.name} />
          </span>
        </div>
        <div className="comments-info w-100">
          <div className="comment-date-person">
            <span className="comment-person">{user.name}</span>
          </div>
          <Form.Group className="w-100">
            <Form.Label htmlFor={label} className="visually-hidden">
              Content
            </Form.Label>
            <SimpleMDE
              id={label}
              value={value}
              onChange={handleEditorChange}
              aria-label={label}
              ref={editorRef}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default CommentEditor;
