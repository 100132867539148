

import "./ParticipantsComponent.scss";
import Participant from "./Participant";
const ParticipantsComponent = ({ data,handleChange }) => {
  return (
    <>
      {data != null && (
        <div className="participants-wrapper">
          {data.map((d, i) => (
            <Participant key={i} part={d} handleChange={handleChange}/>
          ))}
        </div>
      )}
    </>
  );
};

export default ParticipantsComponent;
