import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";

import "./Forms.scss";

import SelectForm from "./SelectForm";
import FormText from "./FormText";


const Forms = ({
  data,
  hasSubmit,
  submitLabel,
  onSubmit,
  onCancel,
  disabled,
  handleInputChange,
  formRef
}) => {
  
  
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <Form
      className={`form-container sidebar-form ${disabled ? "disabled" : ""}`}
      onSubmit={handleSubmit}
      disabled={disabled}
      ref={formRef}
    >
      <div className="inputs-wrapper">
        {data.map((item, index) => (
          <div className={`forms-fields ${item.class ? item.class : ""}`} key={index}>
            {item.fromtype === "text" && (
              <FormText
                label={item.label}
                type={item.type}
                name={item.name}
                value={item.value}
                disabled={item.disabled}
                placeholder={item.placeholder}
                isRequired={false}
                onChange={(e) => handleInputChange(item.name, e.target.value)}
              />
            )}
            {item.fromtype === "select" && (
              <SelectForm
                label={item.label}
                options={item.options}
                defaultVal={item.value}
                controlId={item.controlId}
                name={item.name}
                placeholder={item.placeholder}
                disabled={disabled}
                onChange={(value) => handleInputChange(item.name, value)} 
              />
            )}
          </div>
        ))}
      </div>
      {hasSubmit && (
        <div className="submit-wrapper">
          <Button
            variant="secondary"
            onClick={handleCancel}
            className="ml-2"
            disabled={disabled}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={disabled}>
            {submitLabel}
          </Button>
        </div>
      )}
    </Form>
  );
};

export default Forms;


