import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Protected from "./components/Protected/Protected";
import Tasks from "./pages/Tasks/Tasks";
import DataEntry from "./pages/DataEntry/DataEntry";
import Cases from "./pages/Cases/Cases";
import Case from "./pages/Cases/Case/Case";
import Header from "./components/Header/Header";
import EmptyState from "./components/EmptyState/EmptyState";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import { apiClient } from "./services/apiService";
import { initKeycloak, getToken } from "./utils/keykloak";

function App() {
  const [loading, setLoading] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [, setKeycloakInitialized] = useState(false);

  useEffect(() => {
    const initializeKeycloak = async () => {
      console.log("Keycloack set up");
      try {
        setLoading(true);
        await initKeycloak(); // Await the Keycloak initialization
        const accessToken = getToken();
        if (accessToken) {
          setHasToken(true);
          setAuthenticated(true);
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
        }
      } catch (error) {
        console.error("Failed to initialize Keycloak", error);
        setError(error);
      } finally {
        setKeycloakInitialized(true); // Mark Keycloak as initialized
        setTimeout(() => {
          setLoading(false);
        }, 2000); // Set loading to false after initialization
      }
    };
    initializeKeycloak();
  }, []);

  if (loading) {
    return <LoadingPage />; // Show loading state
  }

  if (error) {
    return <div>{error}</div>; // Show error message
  }

  return (
    <Router>
      {hasToken && (
        <Routes>
          <Route
            path="/"
            element={authenticated ? <Navigate to="/tasks" replace /> : <></>}
          />
          <Route element={<Header />}>
            <Route path="/tasks" element={<Protected Component={Tasks} />} />
            <Route path="/cases" element={<Protected Component={Cases} />} />
            <Route
              path="/data-entry"
              element={<Protected Component={DataEntry} />}
            />
            <Route path="/cases/:id" element={<Protected Component={Case} />} />
            <Route
              path="/cases/create/:id"
              element={<Protected Component={Case} />}
            />
            <Route path="*" element={<EmptyState />} />
          </Route>
        </Routes>
      )}
    </Router>
  );
}

export default App;
