import "./InfoCard.scss";
const InfoCard = ({ label, data, length }) => {
  return (
    <div className="info-card">
      <h2 className="h4 info-card-label text-primary">
        {label}
        {length && length > 1 && (
          <sup className="fw-semibold text-primary">(+{length - 1})</sup>
        )}
      </h2>
      <span className="info-card-data text-primary fw-md">{data}</span>
    </div>
  );
};

export default InfoCard;
