import { useParams } from "react-router-dom";
import EventLineTable from "../Tables/TableLayout/EventLineTable";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useState, useEffect } from "react";
import { fetchCaseHistoryById } from "../../services/apiService";
import "./Eventline.scss";

const Eventline = ({ id, isCreateCasePage }) => {
  let headers = ["Date", "Event", "Ref No.", "User", "File(s)"];
  const [caseHistory, setCaseHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCaseHistory = async () => {
      try {
        const response = await fetchCaseHistoryById(id);
        setCaseHistory(response);
      } catch (err) {
        setError("An error occurred while fetching the case history.");
        console.error(err);
      }
    };
    if (!isCreateCasePage) {
      fetchCaseHistory();
    }
  }, [id]);

  return (
    <section
      className={`eventline-section ${isCreateCasePage ? "disabled" : ""}`}
    >
      <SectionTitle label="Eventline" classn="eventline-title" />
      <EventLineTable
        headers={headers}
        label="No history yet"
        data={caseHistory}
      />
    </section>
  );
};

export default Eventline;
