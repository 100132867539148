import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Calendar } from "react-iconly";

import FormText from "../../Forms/FormText";
import IconComponent from "../../IconComponent/IconComponent";
import CalendarComponent from "../../Calendar/Calendar";
import Card from "../../Card/Card";

const Signature = ({ id,formData,onInputChange }) => {
  const SignatureContent = () => {
    return (
      <Container fluid>
        <Row>
          <Col md={7} className="signature-court">
            <FormText
              type="text"
              name="done_at"
              customClass="fw-normal"
              isRequired={false}
              hideLabel={false}
              value={formData && formData.done_at ? formData.done_at : ""}
              label="Done at:"
              onChange={(event) => onInputChange("done_at","done_at",event.target.value)}
            />
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center bg-fadeblue border-start border-3 py-3 border-warning"
          >
            <IconComponent
              icon={<Calendar label="Calendar" primaryColor="#264687" />}
              text="Date:"
            />
            <CalendarComponent
              label="signature_date"
              handleChange={(date,value) => onInputChange("date",date,value)}
              date={formData && formData.date ? formData.date : ""}
              error={false}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div className="section">
      <Card body={<SignatureContent />} />
    </div>
  );
};

export default Signature;
