// models.ts

// HistoryItem Model
export class HistoryItem {
  id: number; // Unique identifier for the history item
  date: Date; // The date of the event
  evnt_short: string; // Short description of the event
  event_long_text: string; // Detailed description of the event
  createdBy: string; // The user who created the history item
  event: string;
  constructor(data: {
    id: number;
    date: string;
    evnt_short: string;
    event_long_text: string;
    created_by: string;
    event: string;
  }) {
    this.id = data.id;
    this.event = data.event;
    this.date = new Date(data.date); // Convert to Date object
    this.evnt_short = data.evnt_short;
    this.event_long_text = data.event_long_text;
    this.createdBy = data.created_by;
  }
}

// TaskHistoryResponse Model
export class TaskHistoryResponse {
  history: HistoryItem[]; // Array of HistoryItem instances

  constructor(
    historyData: Array<{
      id: number;
      date: string;
      evnt_short: string;
      event_long_text: string;
      created_by: string;
    }>
  ) {
    this.history = historyData.map((item) => new HistoryItem(item));
  }
}
