import deleteIcon from "../../../../assets/delete.svg";
const FileItem = (props) => {
  return (
    <div className="file-container">
      <div className={props.type == "ZIP" ? "file-type zip" : "file-type"}>
        <span>
        {props.type === "application/pdf" ? "PDF" : props.type}</span>
      </div>
      <div className="file-details">
        <span>{props.fileName}</span>
      </div>
      <div className="file-actions">
        {/* <a href="#">
          <img src={deleteIcon} alt="Delete item" aria-label="Delete item" />
          <span className="visually-hidden">Delete</span>
        </a> */}
      </div>
    </div>
  );
};

export default FileItem;
