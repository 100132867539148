import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";

import AccordionTable from "../../../../components/AccordionTable/AccordionTable";
import Eventline from "../../../../components/Eventline/Eventline";
import CreateNewCase from "../../../../components/CreateNewCase/CreateNewCase";
import SideBar from "../../../../components/ScrollableBox/Sidebar/Sidebar";
import React from "react";

const DetailsTab = ({ id, isCreateCasePage, sidebarPosition, parties }) => {
  const [sideNavExpanded, setSideNavExpanded] = useState(false);
  const [getRow, setRow] = useState(id);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function handleResize() {
    // iPhone X width, for example
    if (window.innerWidth <= 375) {
      setSideNavExpanded(false);

      // write other logic here such as disabling hamburger button
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize(); // on-component-mount, check already to see if user has a small device

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle sidebar state change
  useEffect(() => {
    const specificElement = document.querySelectorAll(".add-scroll");
    const isSidebarOpen = document.body.classList.contains("sidebar-open");
    if (!isSidebarOpen && sideNavExpanded) {
      document.body.classList.add("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "auto";
          elem.style.height = `${screenHeight - 320}px`;
        }
      });
    } else {
      document.body.classList.remove("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "";
          elem.style.height = "auto";
        }
      });
    }
    return () => document.body.classList.remove("sidebar-open");
  }, [sideNavExpanded]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={7} className="d-flex flex-column">
            {isCreateCasePage && <CreateNewCase />}
            <AccordionTable
              labeltitle="Parties"
              isCreateCasePage={isCreateCasePage}
              setSideNavExpanded={setSideNavExpanded}
              parties={parties}
            />
            <SideBar
              type="participant"
              setSideNavExpanded={setSideNavExpanded}
              sideNavExpanded={sideNavExpanded}
              getRow={getRow}
              sidebarPosition={sidebarPosition}
              setRow={setRow}
            />
          </Col>
          <Col md={5} className="d-flex flex-column">
            <Eventline isCreateCasePage={isCreateCasePage} id={id} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailsTab;
