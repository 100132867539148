import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";

import SectionHeader from "../../../SectionTitle/SectionTitle";
import SectionTitle from "../TasksContents/SectionTitle";
import FormDescription from "../TasksContents/FormDescription";
import FilesList from "../TasksContents/FilesList";
import FormSubmit from "./FormSubmit/FormSubmit";
import SelectComponent from "../../../Select/Select";
import ParticipantsComponent from "../../../ParticipantsComponent/ParticipantsComponent";

const CreateIssue = ({
  id,
  data,
  setData,
  handleChange,
  formData,
  setFormData,
  handleSubmit,
  handleFileChange,
  handleCancel,
  docs,
  caseData,
}) => {
  const [issue, setIssue] = useState(null);
  const [options, setOptions] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [participantForm, setParticipantForm] = useState([]);
  const [errorRelated, seterrorRelated] = useState(false);
  const [errorParticipants, seterrorParticipants] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      let response = {
        id: id,
        created_by: "Billy Corgan",
        created_at: "2023-08-10 17:19:00",
        updated_at: "2023-08-10 17:19:00",
        case_id: 1,
        caseNumber: caseData.caseNumber, //B414C/66
        description: "",
        attachements: [],
      };
      setIssue(response);
      // setFormData({
      //   files: [],
      // });
      setOptions([
        { value: 1, label: "Robert Plant" },
        { value: 2, label: "Jimmy Page" },
        { value: 3, label: "John Bonham" },
      ]);
      setData(response);

      let parts = [
        {
          id: 1,
          name: "Robert Plant",
          data: [
            {
              value: "option1",
              label: "Address: 123 Broadchurch, Ireland",
              name: "addrs",
            },
            {
              value: "option2",
              label: "Email: rplant@ledzeppelin.com",
              name: "email",
            },
            { value: "option3", label: "ECodex: lorem ipsum", name: "ecodex" },
          ],
        },
        {
          id: 2,
          name: "Jimmy Page",
          data: [
            {
              value: "option1",
              label: "Address: 43 Denmark Str.,01456, London,UK",
              name: "addrs",
            },
            {
              value: "option2",
              label: "Email: j.page@ledzeppelin.com",
              name: "email",
            },
            { value: "option3", label: "ECodex: lorem ipsum", name: "ecodex" },
          ],
        },
        {
          id: 3,
          name: "John Bonham",
          data: [
            {
              value: "option1",
              label: "Address: 6 Baker Str.01456, London, UK",
              name: "addrs",
            },
            {
              value: "option2",
              label: "Email: j.bonham@ledzeppelin.com",
              name: "email",
            },
            { value: "option3", label: "ECodex: lorem ipsum", name: "ecodex" },
          ],
        },
      ];
      setParticipants(parts);

      setDocuments(
        docs.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }, 500);
  }, [id, setIssue, setData, setFormData, setOptions]);

  const handleChangeValues = (name, value) => {
    handleChange(name, value);
    setSelectedParticipants([]);
    const selectedParticipantsArray = value.map((id) =>
      participants.find((participant) => participant.id === id)
    );

    // Update the selectedParticipants state with the found participants
    setSelectedParticipants((prevParticipants) => [
      ...prevParticipants,
      ...selectedParticipantsArray,
    ]);
    seterrorParticipants(false);
  };

  const handleChangeCheckboxes = (event) => {
    const { id, name, checked } = event.target;

    // Check if the id exists in participantForm
    if (participantForm.hasOwnProperty(id)) {
      // If the name exists, update its value
      if (participantForm[id].hasOwnProperty(name)) {
        setParticipantForm((prevParticipantForm) => ({
          ...prevParticipantForm,
          [id]: {
            ...prevParticipantForm[id],
            [name]: checked,
          },
        }));
      } else {
        // If the name doesn't exist, add it as a new attribute
        setParticipantForm((prevParticipantForm) => ({
          ...prevParticipantForm,
          [id]: {
            ...prevParticipantForm[id],
            [name]: checked,
          },
        }));
      }
    } else {
      // If the id doesn't exist, add it with the name as a new attribute
      setParticipantForm((prevParticipantForm) => ({
        ...prevParticipantForm,
        [id]: {
          [name]: checked,
        },
      }));
    }
  };

  const handleChangeRelated = (name, value) => {
    handleChange(name, value);
    setSelectedDocuments([]);
    const selectedRelatedArray = value.map((id) =>
      docs.find((related) => related.id === id)
    );

    // Update the selectedParticipants state with the found participants
    setSelectedDocuments((prevRelated) => [
      ...prevRelated,
      ...selectedRelatedArray,
    ]);
    seterrorRelated(false);
  };

  const handleCancelIssue = () => {
    resetIssue();
    handleCancel();
  };

  const handleSubmitIssue = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      if (participantForm && !participantForm.length) {
        seterrorParticipants(true);
      }
      if (selectedDocuments && !selectedDocuments.length) {
        seterrorRelated(true);
      }
      e.preventDefault();
      e.stopPropagation();
    } else {
      seterrorParticipants(false);
      seterrorRelated(false);
      if (participantForm.length) {
        setFormData({ ...formData, participants: participantForm });
      }
      if (selectedDocuments.length) {
        setFormData({ ...formData, related: selectedDocuments });
      }
      e.preventDefault();
      handleSubmit(e);
      resetIssue();
    }
  };
  const resetIssue = () => {
    setIssue(null);
    setSelectedDocuments([]);
    setOptions([]);
    setParticipants([]);
    setSelectedParticipants([]);
    setParticipantForm([]);
  };

  return (
    <Form
      className="sidebar-form issue-sidebar"
      noValidate
      onSubmit={handleSubmitIssue}
    >
      {issue != null && data != null && formData && (
        <>
          <SectionHeader label="Issue form E" classn="eventline-title" />
          <SectionTitle sectionTitle="Cover letter" />
          <FormDescription
            description={issue.description}
            handleChange={handleChange}
            label="issuedescription"
          />
          <SectionTitle sectionTitle="Choose recipients" />
          <div className="select-component">
            <SelectComponent
              handleChange={handleChangeValues}
              options={options}
              controlId="participantsStatus"
              name="participants"
              placeholder="Add participant"
              defaultVal=""
              isMulti={true}
              disabled={false}
              closeMenuOnSelect={false}
              error={errorParticipants}
              isRequired={false}
            />
          </div>
          {selectedParticipants && selectedParticipants.length > 0 && (
            <ParticipantsComponent
              data={selectedParticipants}
              handleChange={handleChangeCheckboxes}
            />
          )}

          <SectionTitle sectionTitle="Related files" />

          <div className="select-component">
            <SelectComponent
              handleChange={handleChangeRelated}
              options={documents}
              controlId="relatedStatusIssue"
              name="related"
              placeholder="Add a reference"
              defaultVal=""
              isMulti={true}
              disabled={false}
              closeMenuOnSelect={false}
              error={errorRelated}
              isRequired={false}
            />
          </div>
          <FilesList data={selectedDocuments} />
          <Container fluid>
            <FormSubmit
              cancelLabel="Cancel"
              handleCancel={handleCancelIssue}
              submitLabel="Finalize & Send"
            />
          </Container>
        </>
      )}
    </Form>
  );
};

export default CreateIssue;
