// services/apiService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../endpoints/api";
import { Task } from "../models/TaskModel";
import { TaskHistoryResponse } from "../models/HistoryModel";
import { TaskCommentsResponse } from "../models/CommentModel";
import { CaseModel } from "../models/CaseModel";
import { CaseHistory } from "../models/CaseHistoryModel";
import { CaseSearchModel } from "../models/CaseSearchModel";
// import { TaskListModels } from "../models/TaskListModels";
import { CaseDocumentModel } from "../models/CaseDocumentModel";

export const apiClient = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL, // Set your base URL here
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to fetch all tasks
export const fetchAllTasks = async () => {

  const response = await apiClient.get(API_ENDPOINTS.FETCH_TASKS);
  // Map the response data to TaskListModels instances
  console.log(response.data.map((taskData) => new Task(taskData)));
  return response.data.map((taskData) => new Task(taskData));
};

export const getTask = async (id) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${id}`);
    return new Task(response.data); // Convert response to TaskModel
  } catch (error) {
    console.error("Error importing single task:", error);
    return null;
  }
};

// Fetch task history by task ID
export const fetchTaskHistory = async (taskId) => {
  const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${taskId}/history`);
  return new TaskHistoryResponse(response.data);
};

// Fetch task comments by task ID
export const fetchTaskComments = async (taskId) => {
  const response = await apiClient.get(`${API_ENDPOINTS.FETCH_TASKS}/${taskId}/comments`);
  return new TaskCommentsResponse(response.data);

};

export const submitTask = async (formData) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_TASKS}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error submitting task:", error);
    throw error;
  }
};

export const updateTask = async (formData) => {
  try {
    const response = await apiClient.patch(`${API_ENDPOINTS.FETCH_TASKS}/${formData.taskId}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error updateTask task:", error);
    throw error;
  }
};


// Function to fetch a case by ID
export const fetchCaseById = async (id) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/${id}`);
    return new CaseModel(response.data);
  } catch (error) {
    console.error("Error fetching case data", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const fetchCaseHistoryById = async (id) => {
  try {
    const response = await axios.get(`${API_ENDPOINTS.FETCH_CASE}/${id}/history`);
    
    return response.data.map((casehistory) => new CaseHistory(casehistory));
  } catch (error) {
    console.error("An error occurred while fetching the case history", error);
    throw error;
  }
};

// Function to fetch documents associated with a case
export const fetchCaseDocuments = async (caseId) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/${caseId}/documents`);
    return new CaseDocumentModel(response.data); // Return the documents array
  } catch (error) {
    console.error("Error fetching case documents:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

// Function to fetch cases based on search parameters
export const searchCases = async ({ query, from_date, to_date, page = 1, count = 20 }) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/search`, {
      params: {
        query,
        from_date,
        to_date,
        page,
        count,
      },
    });
    return response.data.map(caseData => new CaseSearchModel(caseData));
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

// Function to fetch cases based on search parameters
export const searchRecentCases = async ({ page = 1, count = 20 }, userId) => {
  try {
    const response = await apiClient.get(`${API_ENDPOINTS.FETCH_CASE}/search/${userId}`, {
      params: {
        page,
        count,
      },
    });
    return response.data.map(caseData => new CaseSearchModel(caseData));
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};


export const createCase = async (formData) => {
  try {
    const response = await apiClient.post(`${API_ENDPOINTS.FETCH_CASE}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error create a case:", error);
    throw error;
  }
};


export const submitNote = async (formData) => {
  return apiClient.post('/api/notes', formData);
};

export const submitParticipant = async (formData) => {
  return apiClient.post('/api/participants', formData);
};

export const submitIssue = async (formData) => {
  return apiClient.post('/api/issues', formData);
};
