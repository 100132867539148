// Comment Model
export class CommentModel {
  id: string; // Unique identifier for the comment
  created_by: string; // The user who created the comment
  date: Date; // The date the comment was created
  comment: string; // The comment text in markdown format
  taskId: string;
  constructor(data: {
    id: string;
    createdBy: string;
    createdAt: string;
    comment: string;
    taskId: string;
  }) {
    this.id = data.id;
    this.created_by = data.createdBy;
    this.date = new Date(data.createdAt);
    this.comment = data.comment;
    this.taskId = data.taskId;
  }
}

// TaskCommentsResponse Model
export class TaskCommentsResponse {
  comments: CommentModel[]; // Array of Comment instances

  constructor(
    comments: Array<{
      id: string;
      created_by: string;
      date: string;
      comment: string;
    }>
  ) {
    this.comments = comments.map(
      (commentData) => new CommentModel(commentData)
    );
  }
}

// ErrorResponse Model for 404 and 500
class ErrorResponse {
  message: string; // Error message

  constructor(message: string) {
    this.message = message;
  }
}
