const Th = ({ colSpan, mykey, label, onSort, sortConfig,isSortable }) => {
  const isActive = sortConfig?.key === mykey;
  const sortDirection = isActive ? (sortConfig?.direction === 'asc' ? '↑' : '↓') : '';

  return (
    <th
      colSpan={colSpan}
      onClick={isSortable ? () => onSort(mykey) : undefined}  // Call onSort with the key when header is clicked
      style={{ cursor: isSortable ? "pointer" : "default" }}  // Make it visually clear that it's clickable
    >
      {label} {isActive && sortDirection}
    </th>
  );
};

export default Th;
