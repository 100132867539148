import { useParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCase } from "../../services/apiService";
import Loader from "../Loader/Loader";
import Forms from "../Forms/Forms";
import Card from "../Card/Card";
import "./CreateNewCase.scss";
const BodyLayout = ({
  data,
  hasSubmit,
  submitLabel,
  onSubmit,
  onCancel,
  disabled,
  handleInputChange,
}) => {
  return (
    <div className="body-layout-form">
      <Forms
        data={data}
        hasSubmit={hasSubmit}
        submitLabel={submitLabel}
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={disabled}
        handleInputChange={handleInputChange}
      />
      {disabled && <Loader />}
    </div>
  );
};

const CreateNewCase = () => {
  const naviget = useNavigate();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const handleCancel = () => {
    // Function to handle cancel action
    naviget("/tasks"); // Redirect to home page or any other appropriate page
  };

  const handleSubmit = () => {
    console.log("Form submitted with data:", formData);

    const createSingleCase = async () => {
      setLoading(true); // Start loading state
      try {
        const taskObjects = await createCase(formData); // Use the API service to fetch tasks
        console.log(taskObjects);
      } catch (err) {
        console.log("Response headers:", err);
      } finally {
        setLoading(false); // Set loading to false regardless of outcome
      }
    };
    createSingleCase();
  };
  
  const [formData, setFormData] = useState({
    casenumber: "CB/456-5",
    courtId: "option1",
    departmentId: "option1",
  });

  let dataform = [
    {
      type: "text",
      label: "Case number",
      name: "casenumber",
      fromtype:"text",
      value: "CB/456-5",
      disabled: true,
    },
    {
      type: "select",
      label: "Authority",
      fromtype:"select",
      options: [
        { value: "option1", label: "Galactic Senate" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
      ],
      value: "option1",
      name: "courtId",
      placeholder: "Select authority",
      controlId: "courtId",
    },
    {
      type: "select",
      fromtype:"select",
      label: "Department",
      options: [
        { value: "option1", label: "Intergalactic Trade Authority" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
      ],
      value: "option1",
      name: "departmentId",
      placeholder: "Select department",
      controlId: "departmentId",
    },
  ];
  const handleInputChange = (name, value) => {
    // Update formData state with new form data
    console.log(value);
    setFormData({ ...formData, [name]: value.value });
  };
  return (
    <Card
      title="New case"
      body={
        <BodyLayout
          data={dataform}
          hasSubmit={true}
          submitLabel="Create case"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          disabled={loading}
          handleInputChange={handleInputChange}
        />
      }
    />
  );
};

export default CreateNewCase;
