import Select from "react-select";
import { useState } from "react";
import "./Select.scss";
import { FormLabel } from "react-bootstrap";

import FormMessage from "../Forms/FormMessage/";

const SelectComponent = ({
  handleChange,
  options,
  controlId,
  name,
  placeholder,
  defaultVal,
  disabled,
  isMulti,
  closeMenuOnSelect,
  mystyle,
  error,
  isRequired,
  clearSetting = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isClearable] = useState(clearSetting);
  let customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FAFAFA",
      borderRadius: "6px",
      boxShadow: "none",
      borderColor: "#94A9D4",
      borderBottomLeftRadius: menuIsOpen ? 0 : "6px",
      borderBottomRightRadius: menuIsOpen ? 0 : "6px",
      borderBottomWidth: menuIsOpen ? "0px" : "1px",
      minWidth: "200px",
      "&:hover": {
        borderColor: "#94A9D4", // Change border color on hover
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "0.875rem",
      color: "#325499", // Change the color of the placeholder text here
      whiteSpace: "nowrap", // Prevent the placeholder text from wrapping
      overflow: "hidden", // Hide overflowing text
      textOverflow: "ellipsis", // Add ellipsis when text overflows
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the dropdown arrow
      "&:hover": {
        color: "#335BAB", // Change border color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "none",
      minWidth: "200px",
      backgroundColor: "#FAFAFA",
      fontSize: "0.875rem",
      color: "#325499",
      border: "1px solid #457AE6",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#335BAB", // Change the color of the selected value
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#264687" : "transparent", // Background color of the selected option in the menu
    }),
    // Add more custom styles as needed
  };

  if (mystyle) {
    customStyles = mystyle;
  }

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const defaultOption = options.find((option) => option.value === defaultVal);
  return (
    <div>
      <FormLabel htmlFor={controlId} className="visually-hidden">
        {controlId}
      </FormLabel>
      <Select
        className="sidebar-select"
        controlId={controlId}
        name={name}
        aria-labelledby={controlId}
        inputId={controlId}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti ? true : false}
        options={options}
        isClearable={isClearable}
        // required = {isRequired}
        defaultValue={defaultOption}
        placeholder={placeholder}
        onChange={(selectedValues) => {
          if (isMulti) {
            // For multi-select, selectedValues will be an array of selected options
            const selectedValuesArray = selectedValues.map(
              (option) => option.value
            );
            handleChange(name, selectedValuesArray);
          } else {
            // For single-select, selectedValues will be a single option object
            handleChange(name, selectedValues ? selectedValues.value : "");
          }
        }}
        styles={customStyles}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isDisabled={disabled}
        classNames={{
          menu: () => "custom-menu-class",
        }}
      />
      <FormMessage message={`Please ${placeholder}`} error={error} />
    </div>
  );
};

export default SelectComponent;
