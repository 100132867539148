import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useRef } from "react";

import Forms from "../../../Forms/Forms";
import TableLabel from "../../../Tables/TableLabel/TableLabel";
const AddParticipant = ({ setSideNavExpanded, handleSubmit, handleCancel }) => {
  const [formData, setFormData] = useState(null);

  const [dataform, setDataForm] = useState([
    {
      type: "select",
      fromtype: "select",
      label: "Code:",
      options: [
        { value: "option1", label: "01 Claimant" },
        { value: "option2", label: "02 Defendant" },
        { value: "option3", label: "03 Claimant's representative" },
        { value: "option4", label: "04 Defendant's representative" },
        {
          value: "option5",
          label: "05 Claimant's legally authorised representative",
        },
        {
          value: "option6",
          label: "06 Defendant's legally authorised representative",
        },
      ],
      value: "option1",
      name: "code",
      placeholder: "code",
      class: "",
      controlId: "code",
    },
    {
      type: "text",
      fromtype: "text",
      label: "Name:",
      name: "participant_name",
      placeholder: "Full name",
      value: "",
      disabled: false,
    },
    {
      type: "number",
      label: "Identification code:",
      fromtype: "text",
      name: "participant_idcode",
      placeholder: "Code",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: "Address:",
      fromtype: "text",
      name: "participant_address",
      placeholder: "Address",
      value: "",
      disabled: false,
    },
    {
      type: "number",
      label: "Postal code:",
      fromtype: "text",
      name: "participant_postalcode",
      placeholder: "Postal code",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: "City:",
      fromtype: "text",
      name: "participant_city",
      placeholder: "City",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: "Country:",
      fromtype: "text",
      name: "participant_country",
      placeholder: "Country",
      value: "",
      disabled: false,
    },
    {
      type: "number",
      label: "Phone:",
      fromtype: "text",
      name: "participant_phone",
      placeholder: "Phone",
      value: "",
      disabled: false,
    },
    {
      type: "number",
      label: "Fax:",
      fromtype: "text",
      name: "participant_fax",
      placeholder: "Fax",
      value: "",
      disabled: false,
    },
    {
      type: "email",
      label: "Email:",
      fromtype: "text",
      name: "participant_email",
      placeholder: "Email",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: "Occupation:",
      fromtype: "text",
      name: "participant_occupation",
      placeholder: "Occupation",
      value: "",
      disabled: false,
    },
    {
      type: "text",
      label: "Other details:",
      fromtype: "text",
      name: "participant_details",
      placeholder: "Details",
      value: "",
      disabled: false,
    }
  ]);


  const formRef = useRef(null);
  const resetData = () => {
    const newDataForm = dataform.map((item) => ({
      ...item,
      value: "", // Reset the value to an empty string
    }));
    setDataForm(newDataForm);
    setFormData(null);
    formRef.current.reset();
  };

  const handleCancelParticipants = (e) => {
    // Update the form data state with the cleared values
    handleCancel(e);
    resetData();
  };
  const handleSubmitParticipants = (e) => {
    console.log("Form submitted with data:", formData);
    handleSubmit(e);
    resetData();
  };

  const handleInputChange = (name, value) => {
    // Update formData state with new form data
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="sidebar-wrapper">
        <Container>
          <Row>
            <Col xs={12}>
              <TableLabel label="Add participant" />
            </Col>
          </Row>
        </Container>
      </div>

      <Forms
        data={dataform}
        hasSubmit={true}
        submitLabel="Add"
        onSubmit={handleSubmitParticipants}
        onCancel={handleCancelParticipants}
        disabled={false}
        handleInputChange={handleInputChange}
        formRef={formRef}
      />
    </>
  );
};

export default AddParticipant;
