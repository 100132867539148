import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PaperUpload, Paper } from "react-iconly";
import { X } from "react-bootstrap-icons";
import FormText from "../Forms/FormText";
import CheckboxForm from "../Forms/CheckboxForm";
import SelectComponent from "../Select/Select";
import Form from "react-bootstrap/Form";
import { FormatFileSize } from "../../utils/func";

const UploadDocument = ({ onFileChange, inputFileRef, documents }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [show, setShow] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [linkedDocuments, setLinkedDocuments] = useState(false);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const allowedFileTypes = ["application/pdf"];

  const formatDocumentsForSelect = (documents) => {
    return documents.map((doc) => ({
      value: doc.id,
      label: doc.name,
    }));
  };

  useEffect(() => {
    setDocumentOptions(formatDocumentsForSelect(documents));
  }, []);

  const handleClose = () => {
    setUploadFile(null);
    setLinkedDocuments(false);
    setShow(false);
    setSelectedDocument(null);
    setErrorMessage("");
  };
  const handleShow = () => setShow(true);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const file = event.dataTransfer.files[0];
    validateFile(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file);
  };

  const removeFile = () => {
    setUploadFile(null);
    setLinkedDocuments(false);
  };

  const openFileDialog = (event) => {
    // Trigger click event on the file input
    event.preventDefault();
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleChangeCheckboxes = (event) => {
    setLinkedDocuments(event.target.checked);
    if (!event.target.checked) {
      setErrorMessage("");
    }
  };

  const handleDocuments = (event, value) => {

    if (value) {
      setSelectedDocument(value);
      setErrorMessage("");
    }
  };

  const validateFile = (file) => {
    if (!file) {
      setErrorMessage("No file selected.");
      return;
    }

    if (!allowedFileTypes.includes(file.type)) {
      setErrorMessage("Invalid file type. Only PDF files are allowed.");
      return;
    }

    // If all validations pass
    setUploadFile(file);
    setErrorMessage(""); // Clear error message if valid
  };

  const handleUploadClick = () => {
    if (!uploadFile) {
      setErrorMessage("Please select a file to upload.");
      return;
    }

    if (linkedDocuments && !selectedDocument) {
      setErrorMessage("You must select a file before upload the document.");
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryFileData = event.target.result; // This contains the binary data as ArrayBuffer

      // Create the JSON payload for UploadCaseFileRequest
      const uploadCaseFileRequest = {
        file: binaryFileData, // binary data of the file
        name: uploadFile.name, // name is either provided or generated from the file name
        technical_file_name: uploadFile.name, // actual filename
        is_signed: linkedDocuments, // linkedDocuments will determine if the file is signed
      };
      console.log(uploadCaseFileRequest);
      // Pass the JSON object to the parent component or API
      //onFileChange(uploadCaseFileRequest);
      onFileChange(uploadCaseFileRequest);
      // Reset and close the modal after the file is submitted
      handleClose();
    };

    // Read the file as an ArrayBuffer (binary format)
    reader.readAsArrayBuffer(uploadFile);
  };

  return (
    <>
      <>
        <div className="d-grid gap-2">
          <Button variant="primary" onClick={handleShow}>
            Upload file
            <PaperUpload set="light" />
          </Button>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="card-border"
          centered
        >
          <Modal.Header>
            <Modal.Title>Document(s) upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {uploadFile != null ? (
              <Form>
                <FormText
                  label="Selected File"
                  type="text"
                  name="selected_file"
                  value={uploadFile.name}
                  hideLabel={true}
                  disabled={true}
                />

                <div className="document-wrapper">
                  <Paper set="primary" />
                  {uploadFile.name} -{" "}
                  {<FormatFileSize sizeInBytes={uploadFile.size} />}
                  <Button
                    variant="link"
                    className="p-0 h-auto ms-auto"
                    onClick={() => removeFile()}
                  >
                    <X size="24px" color="#335BAB" className="m-0" />
                    <span className="visually-hidden">Remove</span>
                  </Button>
                </div>

                <CheckboxForm
                  handleChange={handleChangeCheckboxes}
                  controlId="existing_doc"
                  name="existing_doc"
                  label="Link to existing document"
                  customClass="w-fit"
                  reverse={true}
                />

                {linkedDocuments && (
                  <div className="select-component mt-3">
                    <SelectComponent
                      handleChange={handleDocuments}
                      options={documentOptions}
                      controlId="documentStatus"
                      name="document_files"
                      placeholder="Select document"
                      defaultVal=""
                      isMulti={false}
                      disabled={false}
                      closeMenuOnSelect={true}
                      isRequired={true}
                    />
                  </div>
                )}
              </Form>
            ) : (
              <div
                className={`custom-file-input ${
                  isDraggingOver ? "dragging-over" : ""
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  id="file-input"
                  type="file"
                  ref={inputFileRef}
                  className="file-input visually-hidden"
                  onChange={handleFileChange}
                />
                <label
                  className="input-label"
                  htmlFor="file-input"
                  onClick={(e) => openFileDialog(e)} // Pass the event to prevent default
                >
                  Drag and drop or click to upload file (.pdf)
                  <PaperUpload set="primary" />
                </label>
              </div>
            )}
            {errorMessage && (
              <div className="error-message text-danger mt-2">
                <span>{errorMessage}</span>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUploadClick}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default UploadDocument;
