import Th from "./Th";

const Thead = ({ headers, hasActions, hascolSpan2, onSort, sortConfig }) => {
  return (
    <thead>
      <tr>
        {headers.map((header, i) => (
          <Th
            colSpan={(!i || i == 1) && hascolSpan2 ? "2" : "1"}
            mykey={header.key ? header.key : header}
            label={header.label ? header.label : header}
            key={i}
            onSort={header.sortable ? onSort : null}
            sortConfig={sortConfig ? sortConfig : null}
            isSortable={header.sortable ? header.sortable : false}
          />
        ))}
        {hasActions && (
          <th colSpan="1" key="Actions">
            Actions
          </th>
        )}
      </tr>
    </thead>
  );
};

export default Thead;
