import Table from "react-bootstrap/Table";

import { CovertDates } from "../../../utils/func";
import Thead from "./Layout/Thead";

import "./TableLayout.scss";

const EventLineTable = ({ headers, label, data }) => {
  return (
    <div className="table-responsive">
      {data.length > 0 ? (
        <Table hover>
          <Thead headers={headers} hasActions={false} hascolSpan2={false} />
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="colspan1" colSpan="1">
                  <div className="text-nowrap">
                    <CovertDates date={row.date} />
                  </div>
                </td>
                <td className="colspan1" colSpan="1">
                  <span className="w-100 d-block">{row.event_long_name}</span>
                </td>
                <td className="colspan1" colSpan="1">
                  {row.event_code}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.created_by}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.documents.length > 0 &&
                    row.documents.map((file) => file.name).join(", ")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="d-flex justify-content-center align-items-center empty-state-label">
          <span className="text-mainblue">{label}</span>
        </div>
      )}
    </div>
  );
};

export default EventLineTable;
