import React from "react";
import { useState, useEffect } from "react";
import Tables from "../Tables/Tables";
import SideBar from "./Sidebar/Sidebar";
import casedocs from "../../../public/demo/casedocuments.json";
import "./ScrollableBox.scss";
const ScrollableBox = ({ tasks, sidebarPosition, setTasks }) => {
  const [sideNavExpanded, setSideNavExpanded] = useState(false);
  const [getRow, setRow] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function handleResize() {
    // iPhone X width, for example
    if (window.innerWidth <= 375) {
      setSideNavExpanded(false);

      // write other logic here such as disabling hamburger button
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize(); // on-component-mount, check already to see if user has a small device

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // initialize event listeners on-mount & clean on-unmount

  const contentStyle = {
    // marginRight: sideNavExpanded ? "50%" : "0px", // arbitrary values
    transition: "margin 0.2s ease",
  };

  useEffect(() => {
    if (getRow) {
      let response = casedocs;
      setDocuments(response);
    }
  }, [setRow, getRow]);

    // Handle sidebar state change
    useEffect(() => {
      const specificElement = document.querySelectorAll(".add-scroll");
      const isSidebarOpen = document.body.classList.contains("sidebar-open");
      if (!isSidebarOpen && sideNavExpanded) {
        document.body.classList.add("sidebar-open");
        specificElement.forEach((elem) => {
          if (elem) {
            elem.style.overflow = "auto";
            elem.style.height = `${screenHeight - 320}px`;
          }
        });
      } else {
        document.body.classList.remove("sidebar-open");
        specificElement.forEach((elem) => {
          if (elem) {
            elem.style.overflow = "";
            elem.style.height = "auto";
          }
        });
      }
      return () => document.body.classList.remove("sidebar-open");
    }, [sideNavExpanded]);

  return (
    <div>
      {isOpen && (
        <SideBar
          setSideNavExpanded={setSideNavExpanded}
          sideNavExpanded={sideNavExpanded}
          getRow={getRow}
          setRow={setRow}
          tasks={tasks}
          sidebarPosition={sidebarPosition}
          type="task"
          options={documents}
          setTasks={setTasks}
        />
      )}

      <div style={contentStyle}>
        <Tables
          setSideNavExpanded={setSideNavExpanded}
          sideNavExpanded={sideNavExpanded}
          tasks={tasks}
          getRow={getRow}
          setRow={setRow}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default ScrollableBox;
