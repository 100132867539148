import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Dropdown } from "react-bootstrap";

const SubmitForm = ({ id, handleSaveAsDraft,handleCancel }) => {
  
  return (
    <div className="submit-section">
      <Container fluid>
        <Row className="justify-content-end">
          <Col xs="auto">
            <Button
              variant="secondary"
              onClick={handleCancel}
              className="ml-2"
              disabled={false}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Save As
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleSaveAsDraft}>Draft</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <Button variant="primary" type="submit">
              Finalize
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubmitForm;
