const FileItem = (props) => {
  return (
    <div className="file-item">
      <div className={props.type == "ZIP" ? "file-type zip" : "file-type"}>
        <span>{props.type}</span>
      </div>
      <span className="file-title">{props.fileName}</span>
      <a
        href={props.path}
        target="_blank"
        className="stretched-link"
        rel="noopener noreferrer"
      >
        <span className="visually-hidden">Open File</span>
      </a>
    </div>
  );
};

export default FileItem;
