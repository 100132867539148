import FormEditor from "../../../../components/FormEditor/FormEditor";

const FormPreview = ({
  form,
  casedat,
  parties,
  setSelectedForm,
  draftFile,
  setDraftFile,
  createNewFormA,
  setCreateNewFormA,
}) => {
  return (
    <div className={`preview-border ${form ? "show-form" : ""}`}>
      {form !== null ? (
        <FormEditor
          id={form}
          casedat={casedat}
          parties={parties}
          setSelectedForm={setSelectedForm}
          draftFile={draftFile}
          setDraftFile={setDraftFile}
          createNewFormA={createNewFormA}
          setCreateNewFormA={setCreateNewFormA}
        />
      ) : (
        <span>choose a form to create</span>
      )}
    </div>
  );
};

export default FormPreview;
