import { useState } from "react";
import { Form } from "react-bootstrap"; // Import Form component from react-bootstrap
import "./Forms.scss";

const CheckboxForm = ({
  handleChange,
  controlId,
  name,
  label,
  defaultChecked,
  disabled,
  reverse = false,
  customClass = "",
}) => {
  const [isChecked, setIsChecked] = useState(
    defaultChecked ? defaultChecked : ""
  );
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    handleChange(event);
  };

  return (
    <Form.Check // Use Form.Check instead of Checkbox
      className={customClass}
      reverse={reverse}
      controlid={controlId}
      id={controlId}
      name={name}
      checked={isChecked}
      onChange={handleCheckboxChange}
      disabled={disabled}
      label={label} // Move label to the label prop
    />
  );
};

export default CheckboxForm;
