import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SectionTitle = (props) => {
  return (
    <Container fluid className="section-title">
      <Row>
        <Col xs={12}>  
          <h2 className="h4 title fw-semibold">{props.sectionTitle}</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionTitle;