import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SimpleMDE from "react-simplemde-editor";
import Card from "../../../Card/Card";
import { Form } from "react-bootstrap";
import { useEffect, useRef } from "react";
const FreeFormMessage = ({
  id,
  freeMessage,
  setFreeMessage,
  onInputChange,
}) => {
  const FreeFormMessageCardHeader = ({ title, customCls }) => {
    return (
      <Container fluid>
        <Row className="py-3">
          <Col xs={12}>
            <h3 className={`${customCls} text-primary mb-0`}>{title}</h3>
          </Col>
        </Row>
      </Container>
    );
  };

  const FormCardBody = ({ body }) => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12}>{body}</Col>
        </Row>
      </Container>
    );
  };
  const handleEditorChange = (editor) => {
    let value = editor.display.input.prevInput;
    setFreeMessage(value);
    onInputChange("free_message", "free_message", value);
  };

  const editorRef = useRef(null);

  useEffect(() => {
    const editorElement = editorRef.current;

    if (editorElement) {
      const waitForCodeMirror = setInterval(() => {
        const codeMirrorContainer = editorElement.querySelector(".CodeMirror");

        if (codeMirrorContainer) {
          clearInterval(waitForCodeMirror); // Stop checking once found

          // Find the textarea element inside the CodeMirror container
          const textarea = codeMirrorContainer.querySelector("textarea");

          if (textarea) {
            // Set the custom ID for the textarea
            const customTextareaId = "freeFromMessage";
            textarea.id = customTextareaId; // Assign custom ID to textarea

            // Create a visually hidden label and link it to the textarea
            const label = document.createElement("label");
            label.setAttribute("for", customTextareaId); // Link label to the textarea by ID
            label.textContent = "Enter your content:"; // You can set the label's text as needed

            // Add the label above the textarea
            // Instead of insertBefore, we'll append it to the parent of the textarea (codeMirrorContainer)
            codeMirrorContainer.prepend(label); // This will insert the label as the first child of the container

            // Make the label visually hidden but accessible to screen readers
            label.classList.add("visually-hidden");
          }
        }
      }, 100); // Check every 100ms

      // Optionally, clear the interval after some time to avoid an infinite loop
      setTimeout(() => clearInterval(waitForCodeMirror), 5000);
    }
  }, []);

  return (
    <>
      <div className="section">
        <Card
          header={
            <FreeFormMessageCardHeader
              title="Please enter a subject"
              customCls="fw-semibold h2"
            />
          }
          body={
            <FormCardBody
              body={
                <>
                  <Form.Label htmlFor="content" className="visually-hidden">
                    Content
                  </Form.Label>
                  <SimpleMDE
                    ref={editorRef}
                    id="content"
                    value={freeMessage}
                    events={{
                      blur: handleEditorChange,
                    }}
                  />
                </>
              }
            />
          }
        />
      </div>
    </>
  );
};
export default FreeFormMessage;
