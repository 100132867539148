export function isNotEmptyOrNullUndefined(str) {
  return str !== undefined && str !== null && str.trim() !== '';
}

export const downloadPDF = (pdfItem) => {
  // Create a link element
  const link = document.createElement("a");
  // Set the href to the URL of the PDF file
  link.href = pdfItem.url; // Assuming item.url holds the URL of the PDF file
  // Set the download attribute with a filename
  link.download = pdfItem.name; // Optional: you can specify a filename
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  // Trigger the download
  link.click();
  // Remove the link from the document
  document.body.removeChild(link);
};


export const isEmpty = (obj) => {
  return JSON.stringify(obj) === '{}';
};