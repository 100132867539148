import "./IconComponents.scss";

const IconComponent = ({ icon, text, info, customClass }) => {
  return (
    <span className={`icon-wrapper ${customClass ? customClass : ""}`}>
      {icon && <>{icon}</>}
      <span className="text-primary text-nowrap">{text}</span>
      {info && <span className="text-primary">{info}</span>}
    </span>
  );
};

export default IconComponent;
