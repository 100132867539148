import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import Documnets from "../Cases/Case/DossierTab/DossierContent/Documnets";
import FormPreview from "../Cases/Case/FormEditorTab/FormPreview";

import casedocs from "../../../public/demo/casedocuments.json";
import "./DataEntry.scss";

const DataEntry = () => {
  const [drafts, setDrafts] = useState(null);
  const [activePDFId, setActivePDFId] = useState(null);
  const [activePDF, setActivePDF] = useState(null);
  const [selectedForm, setSelectedForm] = useState(0);
  const [draftFile, setDraftFile] = useState(null);
  const [createNewFormA, setCreateNewFormA] = useState(false);
  const getDraftFile = (id) => {};
  useEffect(() => {
    let response = casedocs;
    setDrafts(response);
    setActivePDF(response[0].link);
    setActivePDFId(response[0].id);
  }, []);
  return (
    <div className="data-entry-page">
      <h1 className="visually-hidden">Data Entry</h1>
      {drafts != null  && (
        <Container fluid>
          <Row>
            <Col md={3}>
              <div className="position-sticky top-0">
                <Documnets
                  data={drafts}
                  setActivePDF={setActivePDF}
                  activePDFId={activePDFId}
                  setActivePDFId={setActivePDFId}
                  setDossier={setDrafts}
                  // divRef={divRef}
                  label="Drafts"
                  hasActions={false}
                  hasupload={false}
                  hasIcons={false}
                  type="draft"
                  handleClick={getDraftFile}
                />
              </div>
            </Col>
            <Col md={9} className="d-flex flex-column">
              <div className="form-select-wrapper flex-xl-nowrap flex-wrap title-underline mt-xl-0 mt-3 pb-3">
                <h2 className="h1 text-primary flex-grow-1 align-self-end mb-0 fw-semibold">
                  Form A
                </h2>

                <div className="btn-wrapper">
                  <Button
                    variant="primary"
                    onClick={() => setCreateNewFormA(true)}
                  >
                    Create new form A
                  </Button>
                  <Button variant="primary" disabled>
                    Add translation <Plus size="20px" />
                  </Button>
                </div>
              </div>
              <Row>
                <Col xl={9}>
                  <FormPreview
                    form={selectedForm}
                    casedat={null}
                    parties={[]}
                    setSelectedForm={setSelectedForm}
                    draftFile={draftFile}
                    setDraftFile={setDraftFile}
                    createNewFormA={createNewFormA}
                    setCreateNewFormA={setCreateNewFormA}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default DataEntry;
