import "./Tables.scss";

import Title from "./Title/Title";
import Filters from "../Filters/Filters";
import TableLayoutTasks from "./TableLayout/TableLayoutTasks";

const Tables = ({
  sideNavExpanded,
  setSideNavExpanded,
  tasks,
  setRow,
  getRow,
  setIsOpen,
  isOpen,
}) => {
  const options = [
    { value: "1", label: "Filter One" },
    { value: "2", label: "Filter Two" },
    { value: "3", label: "Filter Three" },
    // Add more options as needed
  ];
  const handleChange = (name, value) => {};
  return (
    <section>
      <div className="section-title-filters d-flex justify-content-between">
        <Title title="My tasks" />
        {false && (
          <Filters
            options={options}
            controlId="tableFilters"
            name="filters"
            placeholder="Sort by"
            handleChange={handleChange}
          />
        )}
      </div>
      <TableLayoutTasks
        setSideNavExpanded={setSideNavExpanded}
        sideNavExpanded={sideNavExpanded}
        tasks={tasks}
        getRow={getRow}
        setRow={setRow}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </section>
  );
};

export default Tables;
