import Table from "react-bootstrap/Table";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";
import { CovertTableDates } from "../../../utils/func";
import { useNavigate } from "react-router-dom";
import Thead from "./Layout/Thead";

import "./TableLayout.scss";

const TableLayoutCases = ({ headers, label, data, hasActions }) => {
  const navigate = useNavigate();
  const handleAction = (action) => {
    // Perform action based on selected dropdown item
    console.log("Action:", action);
  };
  const navigateToCase = (id) => {
    navigate(`/cases/${id}`);
  };

  return (
    <div className="table-responsive border-3 border-top table-shadow">
      {data != null && data.length > 0 ? (
        <Table hover>
          <Thead
            headers={headers}
            hasActions={hasActions}
            hascolSpan2={false}
          />
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => navigateToCase(row.case_id)}>
                <td className="colspan1" colSpan="1">
                  {row.caseNumber ? (
                    <>{row.caseNumber}</>
                  ) : (
                    <Badge bg="softred" text="darkred">
                      unassigned
                    </Badge>
                  )}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.organizational_entity}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.claimant}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.defendant}
                </td>
                <td className="colspan1" colSpan="1">
                  {row.amount}
                </td>
                <td className="colspan1" colSpan="1">
                  <div className="text-nowrap">
                    created: <CovertTableDates date={row.created_at} />
                  </div>
                </td>
                <td className="colspan1" colSpan="1">
                  {row.status == "OPEN" ? (
                    <Badge bg="softgreen" text="darkgreen">
                      OPEN
                    </Badge>
                  ) : (
                    <Badge bg="softyellow" text="darkyellow">
                      {row.status}
                    </Badge>
                  )}
                </td>
                {hasActions && (
                  <td className="colspan1 " colSpan="1">
                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        className="py-0 actions-field"
                      >
                        <ThreeDots />
                        <span className="visually-hidden">Actions</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleAction("Edit")}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Delete")}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="d-flex justify-content-center align-items-center empty-state-label">
          <span className="text-mainblue">{label}</span>
        </div>
      )}
    </div>
  );
};

export default TableLayoutCases;
