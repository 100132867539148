import { Chat } from "react-bootstrap-icons";

import FileItem from "./FileItem";

import "./Description.scss";

const Description = (props) => {
  return (
    <div className="description-box">
      <h2 className="h4 task-title">
        {props.data.title}
        {props.data.comment && (
          <span className="task-comments d-none">
            <span>{props.data.comment}</span>
            <Chat />
          </span>
        )}
      </h2>
      <p className="task-expert span">{props.data.description}</p>
      {props.data.attachments && props.data.attachments.length && (
        <div className="task-files">
          {props.data.attachments.map((file, i) => (
            <FileItem
              key={i}
              type={file.file_type}
              fileName={file.name}
              path={file.link}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Description;
