import moment from "moment";

export const TimestampConverter = ({ time }) => {
  // Convert timestamp to a Date object
  const date = new Date(time);

  // Extract individual components (year, month, day, hour, minute, second)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  // Format the date in the desired format
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

  return formattedDate;
};

export const CovertDates = (props) => {
  let currentDate = moment(props.date).format("DD/MM/YYYY");
  return currentDate;
};

export const CovertCommentsDates = (props) => {
  let currentDate = moment(props.date).format("MMM DD YYYY, h:mmA");
  return currentDate;
};

export const CovertTableDates = (props) => {
  let currentDate = moment(props.date).format("MMM DD");
  return currentDate;
};

export const IsDateExpired = (props) => {
  let currentDate = moment(props.date);
  let today = moment();
  let isDateLessThanToday = moment(currentDate).isBefore(today);
  return isDateLessThanToday;
};

export const CovertTime = (props) => {
  let currentDate = moment(props.date).format("MMMM Do YYYY, h:mm:ss a");
  return props.date;
};

export const ExtractInitials = (props) => {
  if (props.fullName) {
    const names = props.fullName.split(" ");
    const firstNameInitial = names[0].charAt(0);
    const lastNameInitial =
      names.length > 1 ? names[names.length - 1].charAt(0) : "";
    return `${firstNameInitial}${lastNameInitial}`;
  }
};

export const HumanizeDateTime = ({ date }) => {
  const currentDate = moment();
  const datetimeDiff = currentDate.diff(moment(date), "minutes");
  const hoursDiff = currentDate.diff(moment(date), "hours");
  const daysDiff = currentDate.diff(moment(date), "days");
  const monthsDiff = currentDate.diff(moment(date), "months");
  const yearsDiff = currentDate.diff(moment(date), "years");

  if (datetimeDiff < 1) {
    return " just a moment ago";
  } else if (datetimeDiff < 60) {
    return ` ${datetimeDiff} minutes ago`;
  } else if (hoursDiff < 24) {
    return ` ${hoursDiff} hours ago`;
  } else if (daysDiff < 30) {
    return ` ${daysDiff} days ago`;
  } else if (monthsDiff < 12) {
    return ` ${monthsDiff} months ago`;
  } else {
    return ` ${yearsDiff} years ago`;
  }
};

export const FormatFileSize = ({ sizeInBytes }) => {
  if (sizeInBytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const size = parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2));

  return `${size} ${sizes[i]}`;
};

export const FormInfo = (id) => {
  let info = {};
  switch (id) {
    case 0:
      info = {
        title: "Application for a European order for payment",
        content:
          "Article 7 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    case 1:
      info = {
        title:
          "Request to the claimant to complete and/or rectify an application for a European order of payment",
        content:
          "Article 9 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    case 2:
      info = {
        title:
          "Proposal to the claimant to modify an application for a European order for payment",
        content:
          "Article 10 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    case 3:
      info = {
        title:
          "Decision to reject the application for a European order for payment",
        content:
          "Article 11 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    case 4:
      info = {
        title: "European order of payment",
        content:
          "Article 12 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    case 5:
      info = {
        title: "Opposition to a European order for payment",
        content:
          "Article 16 (1) of Regulation (EC) No 1896/2006 of the European Parliament and of the Council creating a European order for payment procedure",
      };
      break;
    default:
      return;
  }
  return info;
};
