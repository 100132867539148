import AccordionCard from "../../../components/AccordionCard/AccordionCard";
import React from "react";
import FormText from "../../../components/Forms/FormText";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Plus, X } from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import SelectForm from "../../../components/Forms/SelectForm";
import codesJSON from "../../../../public/demo/codes.json";

const Parties = ({
  id,
  parties,
  addNewSection,
  setPartiesSections,
  handleArrayTextChange,
  removeSection,
}) => {
  const [options, setOptions] = useState(null);
  //const [participants, setParticipants] = useState(parties);

  useEffect(() => {
    setOptions(codesJSON);
    //setParticipants(parties.flatMap((party) => party.data));
    //setParticipants(parties);
  }, []);

  const PartiesForm = ({ item, index }) => {
    let indx = index + 1;
    return (
      <>
        <>
          <Col xs={12} className="form-section border-bottom border-3">
            <Row>
              <Col xs={5}>
                <SelectForm
                  label={`Participant ${indx}`}
                  options={options}
                  defaultVal={
                    item && item.participation_code
                      ? item.participation_code
                      : ""
                  }
                  controlId={`participation_code${index}`}
                  name="participation_code"
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.value,
                      "parties",
                      "participation_code",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="form-section border-bottom border-3">
            <Row>
              <Col md={6}>
                <FormText
                  label="Corporate name of company or organization"
                  type="text"
                  name={`corporate_name${index}`}
                  value={item ? item.corporate_name : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "corporate_name",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>

              <Col md={6}>
                <FormText
                  label="Identification code (if applicable)"
                  type="text"
                  name={`party_idcode${index}`}
                  value={item ? item.identificationCodeString : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "identificationCodeString",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={6}>
                <FormText
                  label="Surname"
                  type="text"
                  name={`surname${index}`}
                  value={item ? item.surname : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "surname",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={6}>
                <FormText
                  label="First Name"
                  type="text"
                  name={`first_name${index}`}
                  value={item ? item.first_name : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "first_name",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={4}>
                <FormText
                  label="Address"
                  type="text"
                  name={`party_address${index}`}
                  value={item ? item.address : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "address",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={2}>
                <FormText
                  label="Postal code"
                  type="text"
                  pattern="^[A-Za-z0-9\- ]{3,10}$"
                  name={`party_postalcode${index}`}
                  value={item ? item.postal_code : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "postal_code",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={3}>
                <FormText
                  label="City"
                  type="text"
                  name={`party_city${index}`}
                  value={item ? item.city : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "city",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={3}>
                <FormText
                  label="Country"
                  type="text"
                  name={`party_country${index}`}
                  value={item ? item.country : ""}
                  disabled={id !== 0 ? true : false}
                  isRequired={false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "country",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="form-section border-bottom border-3">
            <Row>
              <Col md={4}>
                <FormText
                  label="Phone***"
                  type="number"
                  pattern="^\+?[0-9\s\-().]{7,20}$"
                  name={`party_phone${index}`}
                  value={item ? item.phone : ""}
                  disabled={id !== 0 ? true : false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "phone",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={4}>
                <FormText
                  label="Fax***"
                  pattern="^\+?[0-9\s\-().]{7,20}$"
                  type="number"
                  name={`party_fax${index}`}
                  value={item ? item.fax : ""}
                  disabled={id !== 0 ? true : false}
                />
              </Col>
              <Col md={4}>
                <FormText
                  label="Email***"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  type="email"
                  name={`party_email${index}`}
                  value={item ? item.email : ""}
                  disabled={id !== 0 ? true : false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "email",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={6}>
                <FormText
                  label="Occupation***"
                  type="text"
                  name={`party_occupation${index}`}
                  value={item ? item.occupation : ""}
                  disabled={id !== 0 ? true : false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "occupation",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
              <Col md={6}>
                <FormText
                  label="Other details***"
                  type="text"
                  name={`party_details${index}`}
                  value={item ? item.other_details : ""}
                  disabled={id !== 0 ? true : false}
                  onChange={(event) =>
                    handleArrayTextChange(
                      "parties",
                      item.id,
                      setPartiesSections,
                      parties,
                      event.target.value,
                      "parties",
                      "other_details",
                      "id",
                      "array"
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="form-section info">
            <Row>
              <Col xs="auto">
                <span className="span">*e.g. lawyer</span>
              </Col>
              <Col xs="auto">
                <span className="span">
                  **e.g. parent, guardian, managing director
                </span>
              </Col>
              <Col xs="auto">
                <span className="span">***optional</span>
              </Col>
            </Row>
          </Col>
        </>
      </>
    );
  };

  const PartiesLayout = () => {
    return (
      <>
        {parties.length > 0 ? (
          <>
            {parties.map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 && id == 0 && (
                  <div xs="auto" className="remove-btn-wrapper">
                    <Button
                      variant="link"
                      className="p-0 mb-3 h-auto remove-section ms-auto"
                      onClick={() =>
                        removeSection(
                          item.id,
                          setPartiesSections,
                          parties,
                          "parties",
                          "id"
                        )
                      }
                    >
                      <X size="25px" color="264687" />
                      <span className="visually-hidden">Remove</span>
                    </Button>
                  </div>
                )}
                <PartiesForm item={item} index={index} />
              </React.Fragment>
            ))}
          </>
        ) : (
          <PartiesForm item={null} index={1} />
        )}
      </>
    );
  };

  const CustomHeader = ({ setSection, sections, type }) => {
    return (
      <div className="accordion-action-button">
        <Button
          variant="primary"
          className="h-auto ms-auto"
          onClick={() => addNewSection(setSection, sections, type)}
        >
          Add <Plus size="20px" />
        </Button>
      </div>
    );
  };

  return (
    <>
      {options != null && parties != null && (
        <AccordionCard
          eventKey={1}
          isOpen={true}
          title="2. Parties and representatives"
          body={PartiesLayout()}
          isDisabled={id != 0 ? true : false}
          custom="section"
          customHeader={
            id === 0 ? (
              <CustomHeader
                setSection={setPartiesSections}
                sections={parties}
                type={{
                  id: parties.length + 1,
                  participation_code: "",
                  corporate_name: "",
                  fullname: "",
                  address: "",
                  postal_code: "",
                  city: "",
                  country: "",
                  phone: "",
                  fax: "",
                  email: "",
                  occupation: "",
                  other_details: "",
                  identification_code: [
                    {
                      type: "ID",
                      value: "123456789",
                    },
                  ],
                }}
              />
            ) : (
              ""
            )
          }
        />
      )}
    </>
  );
};

export default Parties;
