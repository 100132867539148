import CheckboxForm from "../Forms/CheckboxForm";

const Participant = ({ part, handleChange }) => {
  return (
    <div className="participant">
      <label>{part.name}</label>
      <div className="participant-info">
        {part.data && part.data.length && (
          <>
            {part.data.map((option, i) => (
              <CheckboxForm
                key={i}
                controlId={part.id}
                handleChange={handleChange}
                name={option.name} // Use a unique name for each CheckboxForm
                label={option.label}
                disabled={false}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Participant;
