import { useEffect, useState } from "react";

import SideBar from "../ScrollableBox/Sidebar/Sidebar";

const OpenSideBar = ({
  options,
  type,
  divRef,
  setsidebarPosition,
  sidebarPosition,
  setSideNavExpanded,
  sideNavExpanded,
  getRow,
  setRow,
  isOpen,
  setIsOpen,
  casedat
}) => {
  
  function handleResize() {
    // iPhone X width, for example
    if (window.innerWidth <= 375) {
      setSideNavExpanded(false);

      // write other logic here such as disabling hamburger button
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize(); // on-component-mount, check already to see if user has a small device

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to get the bottom position of the div
    const getBottomPosition = () => {
      if (divRef.current) {
        const { bottom } = divRef.current.getBoundingClientRect();
        setsidebarPosition(bottom);
      }
    };

    // Call the function when component mounts
    setTimeout(() => {
      getBottomPosition();
    }, "1000");

    // Cleanup function
    return () => {
      // Cleanup if needed
    };
  }, []);
  
  return (
    <>
      {isOpen && (
        <SideBar
          type={type}
          setSideNavExpanded={setSideNavExpanded}
          sideNavExpanded={sideNavExpanded}
          getRow={getRow}
          sidebarPosition={sidebarPosition}
          setRow={setRow}
          options={options}
          setIsOpen={setIsOpen}
          caseData={casedat}
        />
      )}
    </>
  );
};

export default OpenSideBar;
