import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";

import "./DossierTab.scss";
import Documnets from "./DossierContent/Documnets";
import PreviewPDF from "./DossierContent/PreviewPDF";
import CommentsPDF from "./DossierContent/CommentsPDF";
import InfoPDF from "./DossierContent/InfoPDF";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import { getCurrentUser } from "../../../../utils/keykloak";

const DossierTab = ({ id, divRef, setDossier, dossier,casedat }) => {
  const [activePDF, setActivePDF] = useState(null);
  const [activePDFId, setActivePDFId] = useState(null);
  const [formData, setFormData] = useState(null);
  const [comments, setComments] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(getCurrentUser());
  }, []);

  useEffect(() => {
    if (dossier) {
      setActivePDF(dossier[0].link);
      setActivePDFId(dossier[0].id);
      setComments(dossier[0].comments);
    }
  }, [id, dossier]);

  useEffect(() => {
    if (dossier) {
      let tmpComments = dossier.find((doc) => doc.id === activePDFId);
      console.log(tmpComments);
      if (tmpComments != null && tmpComments.comments) {
        setComments(tmpComments.comments);
      }
    }
  }, [activePDFId]);

  return (
    <>
      {dossier != null && activePDF != null && activePDFId != null && (
        <>
          <Container fluid>
            <Row>
              <Col md={3} className="position-md-sticky z-5">
                <Documnets
                  data={dossier}
                  setActivePDF={setActivePDF}
                  activePDFId={activePDFId}
                  setActivePDFId={setActivePDFId}
                  setDossier={setDossier}
                  divRef={divRef}
                  label="Official documents"
                  hasActions={true}
                  hasupload={true}
                  hasIcons={true}
                  casedat={casedat}
                />
              </Col>
              <Col md={9}>
                <Row>
                  <Col lg={12}>
                    <SectionTitle
                      classn="h1 text-primary title-underline"
                      label="Preview"
                    />
                  </Col>
                  <Col lg={8}>
                    <PreviewPDF link={activePDF} />
                    <CommentsPDF
                      id={activePDFId}
                      formData={formData}
                      setFormData={setFormData}
                      user={user}
                      comments={comments}
                    />
                  </Col>
                  <Col lg={4}>
                    <InfoPDF activePDFId={activePDFId} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default DossierTab;
