import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useRef, useEffect } from "react";
import BtnSidebar from "../../components/BtnSidebar/BtnSidebar";
import TableLayoutCases from "../../components/Tables/TableLayout/TableLayoutCases";
import SearchBar from "../../components/SearchBar/SearchBar";
import TableLabel from "../../components/Tables/TableLabel/TableLabel";
import { searchCases } from "../../services/apiService";
// import casesJSON from "../../../public/demo/cases.json";
import { getCurrentUser } from "../../utils/keykloak";
import { searchRecentCases } from "../../services/apiService";

import "./Cases.scss";
function Cases() {
  const divRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recentCases, setRecentCases] = useState(null);
  const [loadingrecentCases, setLoadingRecentCases] = useState(false);

  let headers = [
    "Case number",
    "Organizational entity",
    "Claimant",
    "Defendant",
    "Amount",
    "Date",
    "Status",
  ];

  useEffect(() => {
    const recentUserCase = async () => {
      setLoadingRecentCases(true); // Start loading state
      try {
        let userId = getCurrentUser();
        const recentCase = await searchRecentCases(userId.id); // Use the API service to fetch tasks
        setRecentCases(recentCase); // Set tasks state with fetched tasks
      } catch (err) {
        console.log("Response headers:", err);
        setError("Failed to fetch tasks."); // Set error message
      } finally {
        setLoadingRecentCases(false); // Set loading to false regardless of outcome
      }
    };
    recentUserCase();
  }, []);

  const handleSearch = async (searchTerm) => {
    setLoading(true);
    setError(null);

    // Build the params object based on the API specification
    const params = {
      query: searchTerm,
      from_date: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0], // Default to today - 1 year
      to_date: new Date().toISOString().split("T")[0], // Default to today
      page: 1,
      count: 20,
    };

    try {
      const response = await searchCases(params);
      setSearchResults(response);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("Failed to load search results.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid className="container-wrapper border-bottom" ref={divRef}>
        <h1 className="visually-hidden">Cases</h1>
        <div className="searchbar-button-wrapper">
          <div className="w-100"></div>
          <div className="searchbar-wrapper">
            <SearchBar onSearch={handleSearch} />
          </div>
          <div className="w-100">
            <BtnSidebar
              state={null}
              type="createtask"
              divRef={divRef}
              label="Create new task"
              disabled={false}
            />
          </div>
        </div>
      </Container>
      <div className="add-scroll">
        <Container fluid className="table-section">
          <Row className="justify-content-center">
            <Col md={8}>
              <section>
                <h2 className="visually-hidden">Cases Page</h2>
                <TableLabel label="Search results" />
                <TableLayoutCases
                  hasActions={false}
                  data={searchResults}
                  headers={headers}
                  label="No available results"
                />
              </section>
            </Col>
          </Row>
        </Container>
        <Container fluid className="table-section">
          <Row className="justify-content-center">
            <Col md={8}>
              <section>
                <TableLabel label="Recently accessed cases" />
                <TableLayoutCases
                  data={recentCases}
                  hasActions={false}
                  headers={headers}
                  label="No history yet"
                />
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cases;
