import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";

import Court from "./Sections/Court";
import Parties from "./Sections/Parties";
import FormA from "./Sections/Forms/FormA";
import FormB from "./Sections/Forms/FormB";
import FormC from "./Sections/Forms/FormC";
import FormD from "./Sections/Forms/FormD";
import FormE from "./Sections/Forms/FormE";
import FormG from "./Sections/Forms/FormG";
import FreeFormMessage from "./Sections/Forms/FreeFormMessage";
import Signature from "./Sections/Signature";
import SubmitForm from "./Sections/SubmitForm";
import MyModal from "../Modal/Modal";

import "./FormEditor.scss";

const FormEditor = ({
  id,
  casedat,
  parties,
  setSelectedForm,
  draftFile,
  setDraftFile,
  createNewFormA,
  setCreateNewFormA,
}) => {
  const [formData, setFormData] = useState(null);
  //Form A
  const [partiesSections, setPartiesSections] = useState(parties);
  const [groundSections, setGroundSections] = useState(null);
  const [groundCodes, setGroundCodes] = useState(null);
  const [bordersCodes, setBordersCodes] = useState(null);
  const [code1Codes, setCode1Codes] = useState(null);
  const [code2Codes, setCode2Codes] = useState(null);
  const [code3Codes, setCode3Codes] = useState(null);
  const [costsCodes, setcostsCodes] = useState(null);
  const [evidenceCodes, setEvidenceCodes] = useState(null);
  const [appendix1Codes, setAppendix1Codes] = useState(null);
  const [appendix2Codes, setAppendix2Codes] = useState(null);
  const [interestCodes, setInterestCodes] = useState(null);
  const [interestLetterCodes, setInterestLetterCodes] = useState(null);
  const [currencyCodes, setCurrencyCodes] = useState(null);
  const [paymentCodes, setPaymentCodes] = useState(null);
  const [claimantCodes, setClaimantCodes] = useState(null);
  const [bankCodes, setBankCodes] = useState(null);
  const [crossBorderSections, setCrossBorderSections] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [principalDetails, setPrincipalDetails] = useState(null);
  const [principalSections, setPrincipalSections] = useState([]);
  const [interestSections, setInterestSections] = useState([]);
  const [penaltiesSections, setPenaltiesSections] = useState(null);
  const [costsSections, setCostsSections] = useState([]);
  const [evidencesSections, setEvidencesSections] = useState([]);
  const [additionalStatementsSections, setAdditionalStatementsSections] =
    useState(null);
  const [appendix1Sections, setAppendix1Sections] = useState(null);
  const [appendix2Sections, setAppendix2Sections] = useState(null);
  //Form B
  const [langSections, setLangSections] = useState([]);
  const [langCodes, setlangCodes] = useState(null);
  const [formCodes, setFormCodes] = useState(null);
  const [formCodesSections, setFormCodesSections] = useState([]);

  //Form C
  const [editorValue, setEditorValue] = useState(null);

  //Free Message
  const [freeMessage, setFreeMessage] = useState(null);

  //Form D
  const [articleCodesSections, setArticleCodesSections] = useState([]);
  const [articleCodes, setArticleCodes] = useState(null);

  //Form E
  const [defendantSections, setDefendantSections] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [curencies, setCurencies] = useState(null);

  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility

  const handleCloseModal = () => {
    setShowModal(false); // Hide modal when closed
  };

  const handleConfirmCancel = () => {
    if(id!==0){

      setSelectedForm(null);
    }
    resetFormData();
    setDraftFile();
    setShowModal(false); // Hide modal after cancel confirmation
  };

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      newData[section] = value;
      return newData;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with data: ", formData);
    resetFormData();
    setDraftFile(null);
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const handleSaveAsDraft = () => {
    // Handle save as draft logic here
    console.log("Saved as draft");
  };

  const addSection = (setSection, sections) => {
    setSection([
      ...sections,
      { id: sections.length, selectValue: "", textValue: "" },
    ]);
  };

  const addNewSection = (setSection, sections, type) => {
    setSection([...sections, type]);
  };

  const removeSection = (id, setSection, sections, type, textID = "id") => {
    setSection(sections.filter((section) => section[textID] !== id));
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (newData[type]) {
        const indexToRemove = newData[type].findIndex((item) => item.id === id);
        if (indexToRemove !== -1) {
          newData[type].splice(indexToRemove, 1); // Remove the object at the found index
        }
      }
      return newData;
    });
  };

  useEffect(() => {
    if (createNewFormA) {
      setCreateNewFormA(!createNewFormA);
      resetFormData();
    }
  }, [createNewFormA]);

  useEffect(() => {
    setFormData({
      form_id: id,
    });

    if (draftFile == null) {
      createForm();
    } else {
      initForm(draftFile);
    }
  }, [id, draftFile]);

  const setSelectTextFields = (field, data, section) => {
    if (field in data) {
      const newData = data[field].map((item) => ({
        id: item.id,
        selectValue: item.select,
        textValue: item.text,
      }));
      section(newData);
    } else {
      section([{ id: 0, selectValue: "", textValue: "" }]);
    }
  };

  const setFormDefaultData = (data) => {
    Object.keys(data).forEach((key) => {
      setFormData((prevData) => ({
        ...prevData,
        [key]: data[key],
      }));
    });
  };

  const setFormA = (data) => {
    setSelectTextFields("courtsJurisdiction", data, setGroundSections);
  };

  const setFormB = (data) => {
    setSelectTextFields("form_code", data, setFormCodesSections);
    setSelectTextFields("lang_code", data, setLangSections);
  };

  const setFormMessage = (data, field, setVal) => {
    if (field in data) {
      setVal(data[field]);
    } else {
      setVal("");
    }
  };

  const setFormD = (data) => {
    setSelectTextFields("article_code", data, setArticleCodesSections);
  };
  const setFormE = (data) => {
    if ("defendants" in data) {
      const transformedData = [];
      data["defendants"].forEach((item) => {
        transformedData.push(item);
      });
      setDefendantSections(transformedData);
    } else {
      setDefendantSections([
        {
          id: 0,
          defendant: { id: "", name: "" },
          principal: { currency: "", amount: "", date: "" },
          interest: { currency: "", amount: "", date: "" },
          penalties: { currency: "", amount: "", date: "" },
          costs: { currency: "", amount: "", date: "" },
          total: { currency: "", amount: "", date: "" },
        },
      ]);
    }
  };

  const initForm = (data) => {
    setFormDefaultData(data.form_data);
    switch (data.form_id) {
      case 0:
        setFormA(data.form_data);
        break;
      case 1:
        setFormB(data.form_data);
        break;
      case 2:
        setFormMessage(data.form_data, "modification_text", setEditorValue);
        break;
      case 3:
        setFormD(data.form_data);
        break;
      case 4:
        setFormE(data.form_data);
        break;
      case 5:
        break;
      case 6:
        setFormMessage(data.form_data, "free_message", setFreeMessage);
        break;
      default:
        return <div>Error: Invalid File</div>;
    }
  };

  const createForm = () => {
    if (!parties.length) {
      setPartiesSections([
        {
          id: 1,
          participation_code: "",
          corporate_name: "",
          surname: "",
          first_name: "",
          address: "",
          postal_code: "",
          city: "",
          country: "",
          phone: "",
          fax: "",
          email: "",
          occupation: "",
          other_details: "",
          identificationCodeString: "",
          identificationCode: [{}],
        },
      ]);
    }

    setGroundSections({
      code: "",
      specification: "",
    });
    setCrossBorderSections({
      domicileOrHabitualResidenceOfClaimant: "",
      domicileOrHabitualResidenceOfDefendant: "",
      countyOfCourt: "",
    });
    setBankDetails({
      code: "",
      other: "",
      account_information: {},
    });

    setPrincipalSections([
      {
        id: "ID01",
        code1: "",
        code2: "",
        code3: "",
        explanatoryStatement: "",
        date: null,
        endDate: null,
        amount: "",
      },
    ]);

    setInterestSections([
      {
        referencedClaimId: "ID01",
        code: {
          interestGround: {
            code: "",
            other: "",
          },
          interestPeriod: {
            code: "",
            other: "",
          },
        },
        interestRate: null,
        interestRateOverEcb: null,
        onAmount: null,
        startingFrom: null,
        to: null,
      },
    ]);
    setCostsSections([
      {
        id: 1,
        code: null,
        currency: null,
        amount: null,
        specification: null,
      },
    ]);

    setEvidencesSections([
      {
        referencedClaimId: "ID01",
        code: null,
        description: null,
        date: null,
      },
    ]);

    setPenaltiesSections({
      amount: null,
      description: "",
    });
    setAdditionalStatementsSections({
      text: "",
      signature: {
        signingLocation: null,
        signingDate: null,
      },
    });
    setAppendix1Sections({
      code: null,
      sepaAccountInformation: {},
      creditCardInformation: {},
    });

    setAppendix2Sections({
      code: null,
      caseNumber: null,
      judicialName: null,
      firstName: null,
      surname: null,
      choice02AboveDiscontinue: false,
      choice02AboveContinueNational: false,
      doneAt: null,
      signDate: null,
    });

    setEditorValue("");
    setPrincipalDetails({});
    setFormCodesSections([{ id: 0, selectValue: "", textValue: "" }]);
    setLangSections([{ id: 0, selectValue: "", textValue: "" }]);
    setArticleCodesSections([{ id: 0, selectValue: "", textValue: "" }]);
    setDefendantSections([
      {
        id: 0,
        defendant: { id: "", name: "" },
        principal: { currency: "", amount: "", date: "" },
        interest: { currency: "", amount: "", date: "" },
        penalties: { currency: "", amount: "", date: "" },
        costs: { currency: "", amount: "", date: "" },
        total: { currency: "", amount: "", date: "" },
      },
    ]);
  };

  const resetFormData = () => {
    if (id == 0) {
      setPartiesSections([
        {
          id: 1,
          participation_code: "",
          corporate_name: "",
          surname: "",
          first_name: "",
          address: "",
          postal_code: "",
          city: "",
          country: "",
          phone: "",
          fax: "",
          email: "",
          occupation: "",
          other_details: "",
          identificationCodeString: "",
          identificationCode: [{}],
        },
      ]);
      setGroundSections({
        code: "",
        specification: "",
      });
      setCrossBorderSections({
        domicileOrHabitualResidenceOfClaimant: "",
        domicileOrHabitualResidenceOfDefendant: "",
        countyOfCourt: "",
      });
      setBankDetails({
        code: "",
        other: "",
        account_information: {},
      });

      setPrincipalSections([
        {
          id: "ID01",
          code1: null,
          code2: null,
          code3: null,
          explanatory_statement: null,
          period: null,
          amount: null,
        },
      ]);

      setInterestSections([
        {
          referencedClaimId: "ID01",
          code: {
            interestGround: {
              code: "",
            },
            interestPeriod: {
              code: "",
            },
          },
          interestRate: null,
          interestRateOverEcb: null,
          onAmount: null,
          startingFrom: null,
          to: null,
          other: "",
        },
      ]);
      setCostsSections([
        {
          id: 1,
          code: null,
          currency: null,
          amount: null,
          specification: null,
        },
      ]);

      setEvidencesSections([
        {
          referencedClaimId: "ID01",
          code: null,
          description: null,
          date: null,
        },
      ]);

      setPenaltiesSections({
        amount: null,
        description: "",
      });
      setAdditionalStatementsSections({
        text: "",
        signature: {
          signingLocation: null,
          signingDate: null,
        },
      });
      setAppendix1Sections({
        code: null,
        sepaAccountInformation: {},
        creditCardInformation: {},
      });

      setAppendix2Sections({
        code: null,
        caseNumber: null,
        judicialName: null,
        fullName: null,
        choice02AboveDiscontinue: false,
        choice02AboveContinueNational: false,
        doneAt: null,
        signDate: null,
      });
      setPrincipalDetails({});
    }

    setFormData(null);
    setEditorValue("");
    setFormCodesSections([{ id: 0, selectValue: "", textValue: "" }]);
    setLangSections([{ id: 0, selectValue: "", textValue: "" }]);
    setArticleCodesSections([{ id: 0, selectValue: "", textValue: "" }]);
    setDefendantSections([
      {
        id: 0,
        defendant: { id: "", name: "" },
        principal: { currency: "", amount: "", date: "" },
        interest: { currency: "", amount: "", date: "" },
        penalties: { currency: "", amount: "", date: "" },
        costs: { currency: "", amount: "", date: "" },
        total: { currency: "", amount: "", date: "" },
      },
    ]);
  };

  const isIdExists = (array, idToCheck) => {
    return array.some((item) => item.id === idToCheck);
  };

  const handleMultiple = (label, key, value, index, type = "array") => {
    setFormData((prevData) => {
      const newData = { ...prevData };

      if (newData[label]) {
        if (type === "array") {
          // Handle array type (your original logic for arrays)
          if (isIdExists(newData[label], index)) {
            newData[label][index][key] = value;
          } else {
            newData[label].push({
              id: index,
              [key]: value,
            });
          }
        } else if (type === "object") {
          // Handle object type
          if (newData[label]) {
            newData[label][key] = value;
          } else {
            newData[label] = {
              [key]: value,
            };
          }
        }
      } else {
        // If the label does not exist, create it
        if (type === "array") {
          newData[label] = [
            {
              id: index,
              [key]: value,
            },
          ];
        } else if (type === "object") {
          newData[label] = {
            [key]: value,
          };
        }
      }
      return newData;
    });
  };

  const handleSelectChange = (formlabel, id, event, setSections, sections) => {
    const newSections = sections.map((section) => {
      if (section.id === id) {
        handleMultiple(formlabel, "select", event.value, id);
        return { ...section, selectValue: event.value };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleTextChange = (formlabel, id, event, setSections, sections) => {
    const newSections = sections.map((section) => {
      if (section.id === id) {
        handleMultiple(formlabel, "text", event.target.value, id);
        return { ...section, textValue: event.target.value };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleArrayTextChange = (
    formlabel,
    id,
    setSections,
    sections,
    value,
    field,
    sectionfield,
    textID = "id",
    formLabelType = "object"
  ) => {
    const newSections = sections.map((section) => {
      if (section[textID] === id) {
        if (sectionfield != null) {
          return { ...section, [sectionfield]: value };
        }
        return value;
      }
      return section;
    });
    setSections(newSections);
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (formLabelType == "object") {
        if(newData[formlabel]){
          newData[formlabel] = {
            ...newData[formlabel],
            [field]: sections,
          };
        }else{
          newData[formlabel] = {
            [field]: sections,
          };
        }
      } else {
        newData[formlabel] = newSections;
      }
      return newData;
    });
  };

  const handleSingleTextChange = (
    formLabel,
    field,
    id,
    value,
    setSections,
    sections,
    innerField = false
  ) => {
    let updatedSection;
    if (innerField) {
      // If the value is empty, we need to remove the inner field from the object
      if (value === "") {
        // Create a copy of the current sections
        const { [innerField]: omit, ...rest } = sections[field]; // Omit the innerField if value is empty
        updatedSection = {
          ...sections, // Preserve existing top-level fields
          [field]: rest, // Update with the new structure without the inner field
        };
      } else {
        // If the value is not empty, update the inner field

        const val = {
          ...sections[field], // Preserve existing nested fields
          [innerField]: value, // Update the inner field
        };
        updatedSection = {
          ...sections, // Preserve existing top-level fields
          [field]: val, // Update the nested field
        };
      }

      handleMultiple(formLabel, field, updatedSection[field], id, "object");
    } else {
      // Regular field (no nested object)
      updatedSection = {
        ...sections, // Copy the current sections object
        [field]: value, // Update the specific field in the object
      };
      handleMultiple(formLabel, field, value, id, "object");
    }

    // Update the state with the new section
    setSections(updatedSection);
  };

  const handleSingleSelectChange = (
    formLabel,
    field,
    id,
    event,
    setSections,
    sections
  ) => {
    const updatedSection = {
      ...sections, // Copy the current sections object
      [field]: event.value, // Update the specific field in the object
    };
    // Update the state with the new section
    setSections(updatedSection);
    handleMultiple(formLabel, field, event.value, id, "object");
  };

  const setDefendants = (type, id, value) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (newData[type]) {
        if (isIdExists(newData[type], id)) {
          newData[type][id] = value;
        } else {
          newData[type].push(value);
        }
      } else {
        newData[type] = [value];
      }
      return newData;
    });
  };

  const handleDefendantChange = (id, value, type, element) => {
    const newSections = defendantSections.map((section) => {
      if (section.id === id) {
        const updatedSection = { ...section };
        if (type in updatedSection && element in updatedSection[type]) {
          updatedSection[type][element] = value;
        }
        setDefendants("defendants", id, updatedSection);
        return updatedSection;
      }
      return section;
    });
    setDefendantSections(newSections);
  };

  const addDefendantSection = () => {
    setDefendantSections([
      ...defendantSections,
      {
        id: defendantSections.length,
        defendant: { id: "", name: "" },
        principal: { currency: "", amount: "", date: "" },
        interest: { currency: "", amount: "", date: "" },
        penalties: { currency: "", amount: "", date: "" },
        costs: { currency: "", amount: "", date: "" },
        total: { currency: "", amount: "", date: "" },
      },
    ]);
  };

  const handleCheckBoxes = (formlabel, event) => {
    handleInputChange(formlabel, formlabel, event.target.checked);
  };

  const ChooseForm = ({ id }) => {
    switch (id) {
      case 0:
        return (
          <FormA
            id={id}
            editorValue={editorValue}
            setAppendix1Codes={setAppendix1Codes}
            appendix1Codes={appendix1Codes}
            setEditorValue={setEditorValue}
            groundCodes={groundCodes}
            appendix2Codes={appendix2Codes}
            setAppendix2Codes={setAppendix2Codes}
            setGroundCodes={setGroundCodes}
            setBordersCodes={setBordersCodes}
            setCode1Codes={setCode1Codes}
            code1Codes={code1Codes}
            setInterestCodes={setInterestCodes}
            setcostsCodes={setcostsCodes}
            setEvidenceCodes={setEvidenceCodes}
            evidenceCodes={evidenceCodes}
            costsCodes={costsCodes}
            setInterestLetterCodes={setInterestLetterCodes}
            interestCodes={interestCodes}
            interestLetterCodes={interestLetterCodes}
            setCode2Codes={setCode2Codes}
            code2Codes={code2Codes}
            setCode3Codes={setCode3Codes}
            code3Codes={code3Codes}
            bordersCodes={bordersCodes}
            groundSections={groundSections}
            setGroundSections={setGroundSections}
            handleTextChange={handleSingleTextChange}
            handleSelectChange={handleSingleSelectChange}
            claimantCodes={claimantCodes}
            setClaimantCodes={setClaimantCodes}
            bankCodes={bankCodes}
            setBankCodes={setBankCodes}
            bankDetails={bankDetails}
            setBankDetails={setBankDetails}
            currencyCodes={currencyCodes}
            setCurrencyCodes={setCurrencyCodes}
            principalDetails={principalDetails}
            setPrincipalDetails={setPrincipalDetails}
            setPrincipalSections={setPrincipalSections}
            principalSections={principalSections}
            removeSection={removeSection}
            addNewSection={addNewSection}
            handleArrayTextChange={handleArrayTextChange}
            setInterestSections={setInterestSections}
            interestSections={interestSections}
            setPenaltiesSections={setPenaltiesSections}
            penaltiesSections={penaltiesSections}
            setCostsSections={setCostsSections}
            costsSections={costsSections}
            setEvidencesSections={setEvidencesSections}
            evidencesSections={evidencesSections}
            setAdditionalStatementsSections={setAdditionalStatementsSections}
            additionalStatementsSections={additionalStatementsSections}
            setAppendix1Sections={setAppendix1Sections}
            setAppendix2Sections={setAppendix2Sections}
            appendix1Sections={appendix1Sections}
            appendix2Sections={appendix2Sections}
            setCrossBorderSections={setCrossBorderSections}
            crossBorderSections={crossBorderSections}
            setPaymentCodes={setPaymentCodes}
            paymentCodes={paymentCodes}
          />
        );
      case 1:
        return (
          <FormB
            id={id}
            langSections={langSections}
            setLangSections={setLangSections}
            formCodesSections={formCodesSections}
            setFormCodesSections={setFormCodesSections}
            langCodes={langCodes}
            formCodes={formCodes}
            setlangCodes={setlangCodes}
            setFormCodes={setFormCodes}
            formData={formData}
            addSection={addSection}
            removeSection={removeSection}
            handleSelectChange={handleSelectChange}
            handleTextChange={handleTextChange}
            onInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <FormC
            id={id}
            onInputChange={handleInputChange}
            editorValue={editorValue}
            setEditorValue={setEditorValue}
            formData={formData}
          />
        );
      case 3:
        return (
          <FormD
            id={id}
            onInputChange={handleInputChange}
            articleCodes={articleCodes}
            setArticleCodes={setArticleCodes}
            articleCodesSections={articleCodesSections}
            setArticleCodesSections={setArticleCodesSections}
            addSection={addSection}
            removeSection={removeSection}
            handleSelectChange={handleSelectChange}
            handleTextChange={handleTextChange}
          />
        );
      case 4:
        return (
          <FormE
            id={id}
            onInputChange={handleInputChange}
            formData={formData}
            setDefendantSections={setDefendantSections}
            defendantSections={defendantSections}
            addSection={addDefendantSection}
            setCompanies={setCompanies}
            companies={companies}
            setCurencies={setCurencies}
            curencies={curencies}
            handleDefendantChange={handleDefendantChange}
            removeSection={removeSection}
            handleCheckBoxes={(event) =>
              handleCheckBoxes("joint_liability", event)
            }
          />
        );
      case 5:
        return (
          <FormG
            id={id}
            onInputChange={handleInputChange}
            formData={formData}
          />
        );
      case 6:
        return (
          <FreeFormMessage
            id={id}
            onInputChange={handleInputChange}
            setFreeMessage={setFreeMessage}
            freeMessage={freeMessage}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {id != 6 && (
          <>
            <Court
              id={id}
              casedat={casedat}
              handleTextChange={handleSingleTextChange}
            />
            <Parties
              id={id}
              parties={partiesSections}
              addNewSection={addNewSection}
              setPartiesSections={setPartiesSections}
              handleArrayTextChange={handleArrayTextChange}
              removeSection={removeSection}
            />
          </>
        )}

        <ChooseForm id={id} />
        {id != 0 && (
          <Signature
            id={id}
            formData={formData}
            onInputChange={handleInputChange}
          />
        )}
        <SubmitForm
          id={id}
          handleCancel={handleCancel}
          handleSaveAsDraft={handleSaveAsDraft}
        />
      </Form>
      <MyModal
        show={showModal}
        onHide={handleCloseModal}
        onCancel={handleConfirmCancel}
        modalTitle="Cancel Form"
        modalBody="Are you sure you want to cancel?"
        btnCancel="Close"
        btnSubmit="Yes, Cancel"
      />
    </>
  );
};

export default FormEditor;
