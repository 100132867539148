import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CommentItem from "./CommentItem";
import CommentEditor from "./CommentEditor";

const CommentsList = (props) => {
  return (
    <Container fluid>
      <Row>
        {props.data != null && (
          <>
            {props.data.map((dat, index) => (
              <Col className="comments-item-col" xs={12} key={index}>
                <CommentItem
                  id={dat.id}
                  date={dat.date}
                  comment={dat.comment}
                  person={dat.created_by}
                />
              </Col>
            ))}
          </>
        )}
        <Col xs={12}>
          <CommentEditor
            user={props.user}
            value={props.value}
            handleEditorChange={props.handleEditorChange}
            label={props.label}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CommentsList;
