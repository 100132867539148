
// Define the UserPrincipal interface for created_by property
interface UserPrincipal {
  uid: string; // User ID of the creator of the case
}

interface CourtInterface {
  address: string; // address
  city: string; // city
  postalCode: string; // postalCode
  country: string; // country
}

// Define the VersionedCaseIdentifier interface
interface VersionedCaseIdentifier {
  caseIdentifier: string; // Unique identifier for the case
  caseVersion: string; // Version of the case
}

// Define the CaseMetadata interface
interface CaseMetadata {
  created_at: string; // Date and time when this case has been created
  created_by: UserPrincipal; // Creator of the case
  assignedToDepartment: string; // ID of the department to which the case is assigned
}

// Define the PartyFragment interface (used for claimants and defendants)
interface PartyFragment {
  partyId: {
    id: string; // Unique identifier for the party
    version: number; // Version of the party information
  };
  name: string; // Name of the party
  roleInCaseCode: string; // Role code of the party in the case
  roleInCaseShort: string; // Short description of the role of the party
}

// Define the Complete CaseModel class including CaseMetadata and VersionedCaseIdentifier
export class CaseModel {
  versionedCaseIdentifier: VersionedCaseIdentifier; // Versioned identifier for the case
  caseType: string; // Type of the case (e.g., EPO, TOD)
  caseNumber: string; // Reference number for the case
  caseMetadata: CaseMetadata; // Metadata about the case
  claimant: PartyFragment[]; // List of claimants
  defendant: PartyFragment[]; // List of defendants
  amount?: string; // Formatted amount associated with the case
  created_at: string; // Date and time when this case has been created
  created_by: UserPrincipal; // Creator of the case
  court:CourtInterface;
  // Constructor to initialize the case model
  constructor(data: {
    versionedCaseIdentifier: VersionedCaseIdentifier;
    caseType: string;
    caseNumber: string;
    caseMetadata: CaseMetadata; // Include CaseMetadata
    claimant: PartyFragment[];
    defendant: PartyFragment[];
    amount: string;
    created_at: string;
    court:CourtInterface;
  }) {
    this.versionedCaseIdentifier = data.versionedCaseIdentifier; // Initialize VersionedCaseIdentifier
    this.caseType = data.caseType;
    this.caseNumber = data.caseNumber;
    this.caseMetadata = data.caseMetadata; // Initialize CaseMetadata
    this.claimant = data.claimant;
    this.defendant = data.defendant;
    this.amount = data.amount;
    this.created_at = data.created_at;
    this.court = data.court;
  }

  // Method to get a summary of the case
  getSummary(): string {
    const claimants = this.claimant.map(c => c.name).join(", ");
    const defendants = this.defendant.map(d => d.name).join(", ");
    return `Case ${this.caseNumber}: Claimant - ${claimants}, Defendant - ${defendants}, Amount - ${this.amount}`;
  }

  // Method to format creation date
  getFormattedCreatedAt(): string {
    const date = new Date(this.caseMetadata.created_at);
    return date.toLocaleString(); // Customize date formatting as needed
  }
}
