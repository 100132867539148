import SectionTitle from "../SectionTitle";
import CommentsList from "./CommentsList";
import { useState, useEffect, useRef } from "react";
import { fetchTaskComments } from "../../../../../services/apiService";
const Comments = ({ id, handleEditorChange, formData, user, type }) => {
  const [comments, setComments] = useState(null);
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    const fetchComments = async () => {
      if (type !== "createtask") {
        try {
          const response = await fetchTaskComments(id);
          setComments(response.comments);
        } catch (err) {
          console.error("Error fetching comments:", err);
          setError("Failed to fetch comments."); // Set error message
        }
      }
    };

    fetchComments();
  }, [id, type]);
  return (
    <>
      <SectionTitle sectionTitle="Comments" />
      {error && <div className="error-message">{error}</div>}
        <CommentsList
          data={comments}
          user={user}
          value={formData.comment}
          handleEditorChange={handleEditorChange}
          label="taskcomments"
        />
    </>
  );
};

export default Comments;
