import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";

import companiesjSON from "../../../../../public/demo/companies.json";
import currenciesjSON from "../../../../../public/demo/currencies.json";
import Card from "../../../Card/Card";
import DefendantRepeater from "../../../Repeater/DefendantRepeater";
import CheckboxForm from "../../../Forms/CheckboxForm";
import FormCardHeader from "../CardHeader";

const FormE = ({
  id,
  formData,
  handleCheckBoxes,
  setDefendantSections,
  defendantSections,
  addSection,
  setCompanies,
  companies,
  setCurencies,
  curencies,
  handleDefendantChange,
  removeSection,
}) => {
  useEffect(() => {
    setCompanies(companiesjSON);
    setCurencies(currenciesjSON);
  }, []);

  const FormDCardBody = () => {
    return (
      <Container fluid className="formD-layout-defendant">
        <Row>
          <Col xs={12}>
            <DefendantRepeater
              sections={defendantSections}
              companies={companies}
              curencies={curencies}
              handleDefendantChange={handleDefendantChange}
              removeSection={(id) =>
                removeSection(id, setDefendantSections, defendantSections,"defendants")
              }
            />
          </Col>
          <Col xs={12} className="border-top border-1 border-secondary py-3">
            <CheckboxForm
              handleChange={handleCheckBoxes}
              controlId="joint_liability"
              name="joint_liability"
              label="Joint liability"
              defaultChecked={
                formData && formData.joint_liability
                  ? formData.joint_liability
                  : ""
              }
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {companies != null &&
        companies.length &&
        curencies != null &&
        curencies.length && (
          <div className="section">
            <Card
              header={
                <FormCardHeader
                  title="In accordance with Article 12 of Regulation (EC) No 1896/2006, the court has issued this European order for payment on the basis of the attached application. By virtue of this decision, you are ordered to pay the claimant the following amount:"
                  addAction={() =>
                    addSection(setDefendantSections, defendantSections)
                  }
                />
              }
              body={<FormDCardBody />}
            />
          </div>
        )}
    </>
  );
};
export default FormE;
