import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconComponent from "../../IconComponent/IconComponent";
import CalendarComponent from "../../Calendar/Calendar";
import { Calendar } from "react-iconly";

const DueDate = ({ title, onInputChange, content, value }) => {
  return (
    <Container fluid>
      <Row className="border-bottom border-3">
        <Col md={7}>
          <h3 className="text-primary fw-semibold py-3 h4">{title}</h3>
        </Col>
        <Col
          md={5}
          className="d-flex align-items-center bg-fadeblue border-start border-3 py-3 border-warning bg-light"
        >
          <IconComponent
            icon={<Calendar label="Calendar" primaryColor="#264687" />}
            text="Due date:"
          />
          <CalendarComponent
            label="due_date"
            handleChange={(date, value) =>
              onInputChange("due_date", date, value)
            }
            date={value}
            error={false}
          />
        </Col>
      </Row>
      {content && (
        <Row>
          <Col xs={12} className="py-3">
            {content.map((text, index) => (
              <p key={index} className="text-primary h4 fw-semibold">
                {text}
              </p>
            ))}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DueDate;
