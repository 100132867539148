import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRef } from "react";
import FileItem from "./FileItem";

const FilesList = ({ data, formData, handleFileChange }) => {
  const fileInputRef = useRef(null);
  const handleOpenFileDialog = () => {
    fileInputRef.current.click(); // Trigger click on file input
  };
  return (
    <>
      {((data && data.length > 0) ||
        (formData && formData.length > 0)) && (
          <Container fluid className="section-border-bottom">
            <Row>
              {data && data.length > 0 && (
                <>
                  {data.map((dat, index) => (
                    <Col xs="auto" key={index} className="mt-4">
                      <FileItem type={dat.fileType} fileName={dat.name} />
                    </Col>
                  ))}
                </>
              )}

              <>
                {formData && formData.length > 0 && (
                  <>
                    {formData.map((file, index) => (
                      <Col xs="auto" key={index} className="mt-4">
                        <FileItem type={file.fileType} fileName={file.name} />
                      </Col>
                    ))}
                  </>
                )}
              </>
            </Row>
          </Container>
        )}
    </>
  );
};

export default FilesList;
