
import { Modal, Button } from "react-bootstrap";
import "./Modal.scss";

const MyModal = ({
  show,
  onHide,
  onCancel,
  modalTitle,
  modalBody,
  btnCancel,
  btnSubmit,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {btnCancel}
        </Button>
        <Button variant="primary" onClick={onCancel}>
          {btnSubmit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;
