import Accordion from "react-bootstrap/Accordion";
import { Plus } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import codesJson from "../../../public/demo/codes.json";
import TableParties from "../../components/Tables/TableLayout/TableParties";
import TableLabel from "../../components/Tables/TableLabel/TableLabel";
import { useState, useEffect } from "react";
import "./AccordionTable.scss";

const AccordionTable = ({
  labeltitle,
  isCreateCasePage,
  setSideNavExpanded,
  parties,
}) => {
  const [partiesCodes, setPartiesCodes] = useState(null);

  useEffect(() => {
    setPartiesCodes(codesJson);
  }, []);
  const openSideBAr = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSideNavExpanded(true);
  };

  const getPartyLabel = (participationCode) => {
    const codeEntry = partiesCodes.find((code) => code.value === participationCode);
    return codeEntry ? codeEntry.label : "Unknown Role";
  };

  return (
    <Accordion
      defaultActiveKey="0"
      className={`${isCreateCasePage ? "disabled" : ""}`}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span>{labeltitle}</span>
        </Accordion.Header>

        <div className="accordion-action-button">
          <Link
            to="!#"
            className="btn btn-primary action-button"
            variant="primary"
            onClick={openSideBAr}
          >
            Add <Plus size="20px" />
          </Link>
        </div>

        <Accordion.Body className="overflow-auto">
          {partiesCodes != null && (
            <>
              {parties.map((party, i) => {
                let headers = [
                  "Full name",
                  "Full address",
                  "Phone",
                  "Email",
                  "Occupation",
                ];

                const label = getPartyLabel(party.participation_code);

                return (
                  <div className="section-table-parties" key={i}>
                    <TableLabel label={label} />
                    <TableParties
                      headers={headers}
                      data={party}
                      hasActions={true}
                    />
                  </div>
                );
              })}
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionTable;
