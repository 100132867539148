import "./Card.scss";
const Card = ({ header, footer, title, body,customClass=false }) => {
  return (
    <div className={`card-component ${customClass ? customClass : ""}`}>
      {header && <>{header}</>}
      {title && <h2 className="card-title">{title}</h2>}
      <div className="card-body">{body}</div>
      {footer && <>{footer}</>}
    </div>
  );
};

export default Card;
