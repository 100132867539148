import { useState, useEffect } from "react";
import SideNav from "@trendmicro/react-sidenav";

import { ArrowRight } from "react-iconly";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import SingleTask from "./SideBarContent/SingleTask";
import TaskTopBar from "./SidebarTopBar/TaskTopBar";
import AddParticipant from "./SideBarContent/AddParticipant";
import CreateNote from "./SideBarContent/CreateNote";
import CreateIssue from "./SideBarContent/CreateIssue";
import { getCurrentUser } from "../../../utils/keykloak";
import { FORM_TYPES } from "../../../constants/formTypes";
import {
  updateTask,
  submitTask,
  submitNote,
  submitParticipant,
  submitIssue,
} from "../../../services/apiService";
import "./Sidebar.scss";

const SideBar = ({
  sideNavExpanded,
  setSideNavExpanded,
  getRow,
  setRow,
  sidebarPosition,
  type,
  options,
  setIsOpen,
  setTasks = null,
  caseData,
}) => {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [errorCase, setErrorCase] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    let tmpUser = getCurrentUser();
    setUser(tmpUser);
  }, []);
  // Screen resize effect
  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close sidebar if clicked outside
  useEffect(() => {
    const handleClickOutsideTable = (event) => {
      // Check if the clicked element is inside a table
      if (type === FORM_TYPES.TASK || type === FORM_TYPES.CREATE_TASK) {
        if (
          !event.target.closest("table") &&
          !event.target.closest(".sidebar-form") &&
          !event.target.closest(".sidenav---sidenav---_2tBP") &&
          !event.target.closest(".custom-menu-class") &&
          !event.target.closest(".css-b62m3t-container") &&
          !event.target.classList.contains("css-10wo9uf-option") &&
          !event.target.classList.contains("css-d7l1ni-option") &&
          !event.target.classList.contains("react-datepicker__day") &&
          !event.target.classList.contains("css-1wz0x7f-control") &&
          !event.target.classList.contains("css-w9q2zk-Input2") &&
          !event.target.classList.contains("css-1s49cdy-singleValue") &&
          !event.target.classList.contains("css-4xgw5l-IndicatorsContainer2") &&
          !event.target.closest(".css-12a83d4-MultiValueRemove") &&
          !event.target.closest(".css-v7duua")
        ) {
          // Execute the function if the clicked element is not inside a table
          setRow(null);
          setSideNavExpanded(false);
          if (setIsOpen) {
            setIsOpen(false);
          }
        }
      }
    };

    // Attach the event listener when the component mounts
    document.body.addEventListener("click", handleClickOutsideTable);

    // Detach the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleClickOutsideTable);
    };
  }, []);

  // Handle sidebar state change
  // useEffect(() => {
  //   const specificElement = document.querySelectorAll(".add-scroll");
  //   const isSidebarOpen = document.body.classList.contains("sidebar-open");
  //   if (!isSidebarOpen) {
  //     document.body.classList.add("sidebar-open");
  //     specificElement.forEach((elem) => {
  //       if (elem) {
  //         elem.style.overflow = "auto";
  //         elem.style.height = `${screenHeight - 320}px`;
  //       }
  //     });
  //   } else {
  //     document.body.classList.remove("sidebar-open");
  //     specificElement.forEach((elem) => {
  //       if (elem) {
  //         elem.style.overflow = "";
  //         elem.style.height = "auto";
  //       }
  //     });
  //   }
  //   return () => document.body.classList.remove("sidebar-open");
  // }, [sideNavExpanded]);

  // Clear form and close sidebar
  const handleCancel = () => {
    setFormData(null);
    setSideNavExpanded((prevState) => !prevState);
    setRow(null);
    setIsOpen && setIsOpen((prevState) => !prevState);
  };

  // Update form state based on input change
  const handleChange = (name, value) => {
    setErrorCase(name === "case_id" ? false : errorCase);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // File input change handler
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData({ ...formData, related: selectedFiles });
  };

  // Map type to corresponding API call
  const formSubmitHandlers = {
    [FORM_TYPES.TASK]: updateTask,
    [FORM_TYPES.CREATE_TASK]: submitTask,
    [FORM_TYPES.CREATE_NOTE]: submitNote,
    [FORM_TYPES.PARTICIPANT]: submitParticipant,
    [FORM_TYPES.CREATE_ISSUE]: submitIssue,
  };

  // Form submission handler
  const handleSubmit = async (e, submitValue = null) => {
    e.preventDefault();
    try {
      const submitHandler = formSubmitHandlers[type];
      // Check if submitValue is provided, use it; otherwise, use formData
      const dataToSubmit = submitValue || formData;

      if (submitHandler) {
        const response = await submitHandler(dataToSubmit); // Use the selected data for the API call
        console.log("Form submitted:", dataToSubmit);

        if (type === "task" && response?.id) {
          // Update the task in the tasks state
          setTasks((prevTasks) => {
            return prevTasks.map((task) =>
              task.id === response.id ? { ...task, ...response } : task
            );
          });
        }
        setSideNavExpanded(false);
        handleCancel(); // Clear and close sidebar on success
      } else {
        console.error("Unknown form type");
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const divStyle = sidebarPosition > 0 ? { top: `${sidebarPosition}px` } : null;
  return (
    <>
      <SideNav
        aria-label="Main Navigation"
        style={divStyle || undefined}
        onToggle={(expanded) => {
          if (sidebarPosition) {
            setSideNavExpanded(expanded);
          }
        }}
        onSelect={() => setSideNavExpanded((prevState) => !prevState)}
        expanded={sideNavExpanded}
      >
        <div className="top-bar-wrapper">
          <Container fluid>
            <Row className="justify-content-between align-items-center">
              {(type === FORM_TYPES.TASK ||
                type === FORM_TYPES.CREATE_TASK ||
                type === FORM_TYPES.CREATE_NOTE) && (
                <TaskTopBar
                  getRow={getRow}
                  data={data}
                  type={type}
                  handleChange={handleChange}
                  error={errorCase}
                />
              )}
              <Col md="auto" xs={2} className="ms-md-auto">
                <SideNav.Toggle
                  onClick={() => {
                    setRow(null);
                    setSideNavExpanded((prevState) => !prevState)
                  }}
                >
                  <span className="visually-hidden">Toggle navigation</span>
                  <ArrowRight />
                </SideNav.Toggle>
              </Col>
            </Row>
          </Container>
        </div>
        <>
          {(type === FORM_TYPES.TASK || type === FORM_TYPES.CREATE_TASK) && (
            <SingleTask
              data={data}
              id={getRow}
              setData={setData}
              formData={formData}
              setFormData={setFormData}
              user={user}
              type={type}
              setRow={setRow}
              caseData={caseData}
              options={options}
              handleChange={handleChange}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              handleFileChange={handleFileChange}
              sideNavExpanded={sideNavExpanded}
              setErrorCase={setErrorCase}
              setSideNavExpanded={setSideNavExpanded}
            />
          )}
          {type === FORM_TYPES.PARTICIPANT && (
            <AddParticipant
              setSideNavExpanded={setSideNavExpanded}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          )}
          {type === FORM_TYPES.CREATE_NOTE && (
            <CreateNote
              setSideNavExpanded={setSideNavExpanded}
              data={data}
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              handleFileChange={handleFileChange}
              handleCancel={handleCancel}
              id={getRow}
              setRow={setRow}
              setData={setData}
              user={user}
              options={options}
              caseData={caseData}
            />
          )}
          {type === FORM_TYPES.CREATE_ISSUE && (
            <CreateIssue
              docs={options}
              setSideNavExpanded={setSideNavExpanded}
              data={data}
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              handleFileChange={handleFileChange}
              handleCancel={handleCancel}
              id={getRow}
              setData={setData}
              caseData={caseData}
            />
          )}
        </>
      </SideNav>
    </>
  );
};

export default SideBar;
