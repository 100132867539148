import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import SideBar from "../ScrollableBox/Sidebar/Sidebar";

const BtnSidebar = ({
  disabled,
  state,
  options,
  type,
  divRef,
  label,
  caseData = false,
}) => {
  const [sidebarPosition, setsidebarPosition] = useState(0);
  const [sideNavExpanded, setSideNavExpanded] = useState(false);
  const [getRow, setRow] = useState(state);
  const [isOpen, setIsOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  function handleResize() {
    // iPhone X width, for example
    if (window.innerWidth <= 375) {
      setSideNavExpanded(false);

      // write other logic here such as disabling hamburger button
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize(); // on-component-mount, check already to see if user has a small device

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to get the bottom position of the div
    const getBottomPosition = () => {
      if (divRef.current) {
        const { bottom } = divRef.current.getBoundingClientRect();
        setsidebarPosition(bottom);
      }
    };

    // Call the function when component mounts
    setTimeout(() => {
      getBottomPosition();
    }, "1000");

    // Cleanup function
    return () => {
      // Cleanup if needed
    };
  }, []);

  const openSidebar = (event) => {
    setIsOpen(true);
    event.preventDefault();
    event.stopPropagation();
    setSideNavExpanded(true);
  };
  // Handle sidebar state change
  useEffect(() => {
    const specificElement = document.querySelectorAll(".add-scroll");
    const isSidebarOpen = document.body.classList.contains("sidebar-open");
    if (!isSidebarOpen && sideNavExpanded) {
      document.body.classList.add("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "auto";
          elem.style.height = `${screenHeight - 320}px`;
        }
      });
    } else {
      document.body.classList.remove("sidebar-open");
      specificElement.forEach((elem) => {
        if (elem) {
          elem.style.overflow = "";
          elem.style.height = "auto";
        }
      });
    }
    return () => document.body.classList.remove("sidebar-open");
  }, [sideNavExpanded]);
  return (
    <>
      <div className="btn-wrapper" onClick={openSidebar}>
        <Button variant="primary" disabled={disabled}>
          {label}
        </Button>
      </div>
      {isOpen && (
        <SideBar
          type={type}
          setIsOpen={setIsOpen}
          setSideNavExpanded={setSideNavExpanded}
          sideNavExpanded={sideNavExpanded}
          getRow={getRow}
          sidebarPosition={sidebarPosition}
          setRow={setRow}
          options={options}
          caseData={caseData}
        />
      )}
    </>
  );
};

export default BtnSidebar;
