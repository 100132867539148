import Card from "../../../Card/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SimpleMDE from "react-simplemde-editor";
import DueDate from "../DueDate";
import { Form } from "react-bootstrap";
const FormC = ({
  id,
  onInputChange,
  editorValue,
  setEditorValue,
  formData,
}) => {
  const CardHeader = ({ title }) => {
    return (
      <Container fluid className="py-3">
        <Row>
          <Col xs={12}>
            <h3 className="p fw-semibold text-primary h4">{title}</h3>
          </Col>
        </Row>
      </Container>
    );
  };

  const CardBody = ({ body }) => {
    return (
      <Container fluid className="pb-3">
        <Row>
          <Col xs={12}>{body}</Col>
        </Row>
      </Container>
    );
  };

  const handleEditorChange = (editor) => {
    let value = editor.display.input.prevInput;
    setEditorValue(value);
    onInputChange("modification_text", "modification_text", value);
  };

  const formCDATE = (formData) => {
    return (
      <DueDate
        title="Please send your reply to the court as soon as possible and in any event by"
        onInputChange={onInputChange}
        value={formData && formData.due_date ? formData.due_date : ""}
        content={[
          "If you fail to send your reply to the court within the time limit set out above or refuse this proposal, the court will reject your application for a European order for payment, under the conditions provided for in the Regulation, in its entirety.",
          "If you accept this proposal, the court will issue a European order for payment for that part of the claim. It depends on the national law of the Member State where the court is seized whether you will be able, in further proceedings, to recover the remaining part of your initial claim not covered by the European order for payment.",
        ]}
      />
    );
  };

  return (
    <>
      <div className="section">
        <Card
          header={
            <CardHeader title="After examination of your application for a European order for payment, the court considers that the necessary requirements are met for only a part of the  claim. Therefore, the court proposes the following modification to the application:" />
          }
          body={
            <CardBody
              body={
                <>
                  <Form.Label htmlFor="content" className="visually-hidden">
                    Content
                  </Form.Label>
                  <SimpleMDE
                    id="content"
                    value={editorValue}
                    events={{
                      blur: handleEditorChange,
                    }}
                  />
                </>
              }
            />
          }
        />
      </div>
      <div className="section">
        <Card body={formCDATE(formData)} />
      </div>
    </>
  );
};
export default FormC;
