// models/TaskModel.ts

export interface RelatedFile {
  documentId:string;
  fileType: string;
  name: string;
  link: string;
}

export interface RelatedCase {
  caseId:string;
  caseNumber: string;
  department: string;
  claimant: string;
  defendant: string;
  amount?: string;
}

export class Task {
  status: string;
  case_id?: string;
  caseNumber: string;
  duedate_at?: string; // Date-time string (ISO format)
  updated_at?: string;
  assignee_id?: string; // Assignee ID
  title: string; // Title of the task
  description?: string;
  attachments?: string[]; // Array of document IDs (strings)
  comment?: string;
  id: string; // UUID of the task
  related_files?: RelatedFile[]; // Array of related files
  related_case?: RelatedCase | null; // Nullable related case
  created_at?: string; // Date-time string (ISO format)

  constructor(data: {
    status: string;
    caseId: string;
    caseNumber: string;
    dueDateAt: string;
    assigneeId: string;
    title: string;
    description: string;
    attachments: string[];
    comment: string;
    id: string;
    associatedFiles: RelatedFile[];
    relatedCase: RelatedCase | null;
    createdAt: string;
    updatedAt: string;
  }) {
    this.status = data.status;
    this.case_id = data.caseId;
    this.caseNumber = data.caseNumber;
    this.duedate_at = data.dueDateAt;
    this.assignee_id = data.assigneeId;
    this.title = data.title;
    this.description = data.description;
    this.attachments = data.attachments;
    this.comment = data.comment;
    this.id = data.id;
    this.related_files = data.associatedFiles;
    this.related_case = data.relatedCase;
    this.created_at = data.createdAt;
    this.updated_at = data.updatedAt;
  }
}
